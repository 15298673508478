import React from 'react'
import { PartDetails } from '../../components'

export const PartsDetailsPage = () => {
  return (
    <>
      <PartDetails />
    </>
  )
}
