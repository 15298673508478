import { Box, Button } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { validationSchema } from './validation'
import { FieldText, Loader } from '../common'
import { useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { CatalogueSearchResults } from '../Catalogue'

export const SearchVIN = () => {
  const [searchVin, setSearchVin] = useState('')
  const queryClient = useQueryClient()

  const {
    data: searchResults,
    isFetching: isSearchResultsLoading,
    refetch: refetchSearch
  } = useQuery(['search-by-vin'], () => api.catalogueSearchByVIN(searchVin), {
    enabled: false,
    retry: false
  })

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        vin: ''
      },
      validationSchema,
      validateOnBlur: true,
      onSubmit: () => {
        queryClient.removeQueries('search-by-vin')
        refetchSearch()
      }
    })

  useEffect(() => {
    setSearchVin(values?.vin)
  }, [values])

  return (
    <>
      {Boolean(isSearchResultsLoading) && <Loader />}

      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        flexDirection="column"
        width="100%"
        maxWidth="700px"
        mx="auto">
        <StyledForm onSubmit={handleSubmit}>
          <FieldText
            name="vin"
            value={values['vin']}
            label="VIN код. Наприклад: 1VWBS7A30EC087650"
            handleChange={handleChange}
            errors={errors}
            required={true}
            touched={touched}
            handleBlur={handleBlur}
            width="100%"
            fullWidth
          />
          <Box my={2}>
            <StyledSubmit type="submit" variant="outlined">
              Пошук
            </StyledSubmit>
          </Box>
        </StyledForm>
        <CatalogueSearchResults list={searchResults?.data || []} />
      </Box>
    </>
  )
}

const StyledSubmit = styled(Button)`
  padding: 13px !important;
`

const StyledForm = styled.form`
  display: flex;
  margin: 16px 0;
  width: 100%;
  background-color: #fff;
`
