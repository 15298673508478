import React, { FC } from 'react'
import { CustomModal, FieldText } from '../../common'
import { Box, Button, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { IBrand } from '../../../types'
import styled from 'styled-components'
import { addBrandValidationSchema } from './validations'

interface IAddProviderModal {
  defaultValues?: IBrand
  isOpen: boolean
  setIsOpen: (p: boolean) => void
  setDefaultValues: (p: IBrand | undefined) => void
}

export const AddBrandModal: FC<IAddProviderModal> = ({
  defaultValues,
  isOpen,
  setIsOpen,
  setDefaultValues
}) => {
  const queryClient = useQueryClient()
  const addBrand = useMutation(api.addBrand, {
    onSuccess: (resp: any) => {
      toast.success('Added Brand ' + resp?.data?.name)
      handleClose()
      queryClient.invalidateQueries('brands')
    }
  })
  const updateBrand = useMutation(api.updateBrand, {
    onSuccess: () => {
      toast.success('Updated Brand')
      handleClose()
      queryClient.invalidateQueries('brands')
    }
  })

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: defaultValues || {
      id: '',
      name: ''
    },
    validationSchema: addBrandValidationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (Boolean(defaultValues)) {
        if (values?.id) updateBrand.mutate({ ...values })
      } else {
        addBrand.mutate({ data: values.name })
      }
    }
  })

  const handleClose = () => {
    resetForm()
    setIsOpen(false)
    setDefaultValues(undefined)
  }

  return (
    <>
      <CustomModal isOpen={isOpen} handleClose={handleClose}>
        <Typography variant="h5">Add Brand</Typography>
        <StyledForm onSubmit={handleSubmit}>
          <FieldText
            name="name"
            value={values['name']}
            label="Name"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <Box mt={6}>
            <StyledSubmit type="submit" variant="contained" fullWidth>
              Submit
            </StyledSubmit>
          </Box>
        </StyledForm>
      </CustomModal>
    </>
  )
}

const StyledForm = styled.form`
  width: 400px;
  flex-direction: column;
`
const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
