import React, { FC } from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'

interface IBrandSelect {
  isSuccess: boolean
  activeBrand: string
  availableBrands: { id: string; name: string }[]
  onBrandSelect: (name: string) => void
}

export const BrandSelect: FC<IBrandSelect> = ({
  isSuccess,
  activeBrand,
  availableBrands,
  onBrandSelect
}) => {
  if (availableBrands.length === 0 && isSuccess)
    return <>Не знайдено доступних позицій</>
  return (
    <>
      {!Boolean(activeBrand) && Boolean(availableBrands.length !== 0) && (
        <BrandsWrapper>
          Оберіть бренд:
          <ItemsWrapper mt={2}>
            {availableBrands.map(({ name }) => (
              <StyledBox
                key={'brand-select' + name}
                className={activeBrand === name ? 'active' : ''}
                onClick={() => onBrandSelect(name)}>
                {name}
              </StyledBox>
            ))}
          </ItemsWrapper>
        </BrandsWrapper>
      )}

      {Boolean(activeBrand) && (
        <StyledBox className="active">{activeBrand}</StyledBox>
      )}
    </>
  )
}

const StyledBox = styled(Box)`
  cursor: pointer;
  padding: 15px 10px;
  border-top: 1px solid #d4d4d4;

  &&:first-of-type {
    border-top: none;
  }

  &:hover,
  &.active {
    background-color: #d0dfff;
  }
`

const BrandsWrapper = styled(Box)`
  background-color: #fff;
`

const ItemsWrapper = styled(Box)`
  border: 1px solid #d4d4d4;
`
