import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { useFormik } from 'formik'
import React, { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { ICart, ICartItem } from '../../types/cart'
import { StyledTableCell } from '../admin/Providers/ProvidersList'
import { CustomModal, FieldText, Loader } from '../common'
import { toast } from 'react-toastify'
import { cloneDeep, isEqual } from 'lodash'
import { useMutation } from 'react-query'
import { api } from '../../utils/api'
import { getDelivery } from '../../utils/getDeliveryDate'
import { FieldCheckbox } from '../common/FieldCheckbox'
import CloseIcon from '@mui/icons-material/Close'
import { cartItemTimeout } from '../../utils'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import useLocalStorage from 'use-local-storage'

interface ICartModal {
  isOpen: boolean
  setIsOpen: (p: boolean) => void
}

export const CartModal: FC<ICartModal> = ({ isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cartLocal, setCartLocal] = useLocalStorage<ICart>('cart', {
    comment: '',
    items: []
  })
  const cart = cartLocal || {}
  const today = new Date()

  const submitOrder = useMutation(api.submitOrder, {
    onSuccess: () => {
      setIsOpen(false)
      const clonedCart = cloneDeep(cart)
      clonedCart.items = clonedCart.items
        ?.filter((item: ICartItem) => !item?.checked)
        ?.map((item: ICartItem) => ({
          ...item,
          delivery: getDelivery(item.delivery, item.orderBy, today)
        }))

      setCartLocal(clonedCart)
      resetForm()
      toast.success('Замовлення успішно відправлено')
    },
    onSettled: () => setIsLoading(false)
  })

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: {
      comment: cart?.comment || ''
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      const clonedCart = cloneDeep(cart)
      clonedCart.items = clonedCart.items
        ?.filter((item: ICartItem) => item?.checked)
        ?.map((item: ICartItem) => ({
          ...item,
          delivery: getDelivery(item.delivery, item.orderBy, today)
        }))
      setIsLoading(true)
      submitOrder.mutate({ ...clonedCart, ...values })
    }
  })

  const handleRemoveItem = (item: ICartItem) => {
    const clonedCart = cloneDeep(cart)
    const index = clonedCart.items.findIndex((row: ICartItem) =>
      isEqual(row, item)
    )
    if (index >= 0) {
      clonedCart.items.splice(index, 1)
      setCartLocal(clonedCart)
    }
  }

  const handleChangeQuantity = (item: ICartItem, newQuantity: number) => {
    const clonedCart = cloneDeep(cart)
    const index = clonedCart.items.findIndex((row: ICartItem) =>
      isEqual(row, item)
    )

    if (index >= 0 && newQuantity && newQuantity <= item.maxCount) {
      clonedCart.items[index].count = newQuantity
      setCartLocal(clonedCart)
    }
  }

  const handleChecked = useCallback(
    (item: ICartItem, newValue: boolean) => {
      const clonedCart = cloneDeep(cart)
      const index = clonedCart.items.findIndex((row: ICartItem) =>
        isEqual(row, item)
      )

      clonedCart.items[index].checked = newValue
      setCartLocal(clonedCart)
    },
    [cart]
  )

  const sum = cart?.items
    ?.map((item: ICartItem) => (item?.checked ? item.price * item.count : 0))
    ?.reduce((acc: number, curr: number) => acc + curr, 0)

  return (
    <CustomModal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
      {isLoading && <Loader />}
      <Box display="flex" justifyContent="flex-end" mb={1.2} mt={-2}>
        <IconButton
          onClick={() => setIsOpen(false)}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={isOpen ? 'admin-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}>
          <CloseIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" />
              <StyledTableCell align="center">Артикул</StyledTableCell>
              <StyledTableCell align="center">Бренд</StyledTableCell>
              <StyledTableCell align="center">Кількість</StyledTableCell>
              <StyledTableCell align="center">Доставка</StyledTableCell>
              <StyledTableCell align="center">
                Ціна за шт. (грн)
              </StyledTableCell>
              <StyledTableCell align="center">Сума</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(cart?.items) &&
              cart?.items?.map((item: ICartItem) => {
                let isOutdated = false
                if (item?.addedAt) {
                  const addedDate = new Date(item.addedAt).getTime()
                  const now = new Date().getTime()
                  if (addedDate + cartItemTimeout < now) {
                    isOutdated = true
                  }
                }

                if (item?.checked && isOutdated) {
                  handleChecked(item, false)
                }

                return (
                  <TableRow
                    key={
                      'part' +
                      item.partNumber +
                      item.brandName +
                      item.price +
                      item.providerId +
                      item.count +
                      Math.random() * 100
                    }
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: isOutdated ? '#fcd4d4' : 'transparent'
                    }}>
                    <StyledTableCell align="center">
                      {isOutdated ? (
                        <Tooltip title="Пропозиція застаріла, повторіть пошук!">
                          <ErrorOutlinedIcon color="error" />
                        </Tooltip>
                      ) : (
                        <CheckboxWrapper display="flex" justifyContent="center">
                          <FieldCheckbox
                            name="cart-item"
                            value={item?.checked || false}
                            handleChange={(e) =>
                              handleChecked(item, e.target.checked)
                            }
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            mr={0}
                          />
                        </CheckboxWrapper>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.partNumber}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item?.brandName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <StyledButton
                          onClick={() =>
                            handleChangeQuantity(item, item.count - 1)
                          }
                          variant="outlined">
                          -
                        </StyledButton>
                        <Typography minWidth="40px">{item.count}</Typography>
                        <StyledButton
                          onClick={() =>
                            handleChangeQuantity(item, item.count + 1)
                          }
                          variant="outlined">
                          +
                        </StyledButton>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <span>
                        {isOutdated ? (
                          <Button
                            onClick={() =>
                              (window.location.href = `/?brand=${item?.brandName}&partNumber=${item.partNumber}`)
                            }
                            variant="contained">
                            Знайти
                          </Button>
                        ) : (
                          getDelivery(item.delivery, item.orderBy, today)
                        )}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.price}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.count * item.price}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="Натисніть щоб видалити" placement="top">
                        <DeleteOutlinedIcon
                          color="warning"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveItem(item)}
                        />
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={2} display="flex" justifyContent="flex-end">
        Сума обраного: {sum}грн
      </Box>

      <StyledForm onSubmit={handleSubmit}>
        <FieldText
          name="comment"
          value={values['comment']}
          label="Коментар"
          placeholder="Наприклад, спосіб розрахунку або доставки"
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          multiline={true}
          rows={4}
          fullWidth
        />

        <StyledSubmit
          type="submit"
          variant="contained"
          fullWidth
          disabled={sum === 0 || isLoading}>
          {sum === 0 ? 'Оберіть товари' : 'Замовити обране'}
        </StyledSubmit>
      </StyledForm>
    </CustomModal>
  )
}

const StyledForm = styled.form`
  width: 100%;
  flex-direction: column;
`
const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 0;
  }
`
const CheckboxWrapper = styled(Box)`
  min-width: 42px;
  padding-left: 11px;

  label {
    margin-right: 0;
  }
`
