import React from 'react'
import { Box } from '@mui/material'
import { UserSettings } from '../../components'

export const UserSettingsPage = () => {
  return (
    <Box display="flex" justifyContent="center">
      <UserSettings />
    </Box>
  )
}
