import React from 'react'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const Page404 = () => {
  return (
    <Box textAlign="center" mt={6}>
      <Typography variant="h1" fontWeight={500}>
        404
      </Typography>
      <Box mt={3}>
        Сторінку не знайдено. <Link to="/">Повернутись на головну</Link>
      </Box>
    </Box>
  )
}
