import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import { FieldText, Loader } from '../common'
import { Box, Button } from '@mui/material'
import styled from 'styled-components'
import { useMutation } from 'react-query'
import { api } from '../../utils/api'
import { BrandSelect } from './BrandSelect'
import { validationSchema } from './validation'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IPart } from '../../types/search'

interface ISearchField {
  availableBrands: { id: string; name: string }[]
  setAvailableBrands: (p: any) => void
  setAnalogs: React.Dispatch<React.SetStateAction<IPart[]>>
  analogs: IPart[]
  activeBrand: string
  setActiveBrand: React.Dispatch<React.SetStateAction<string>>
}

export const SearchField: FC<ISearchField> = ({
  availableBrands,
  setAvailableBrands,
  setAnalogs,
  analogs,
  activeBrand,
  setActiveBrand
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getAnalogs = useMutation(api.getAnalogsByPartNumberBrand, {
    retry: false,
    onSuccess: (data) => {
      setAnalogs(data?.data)
    },
    onError: () => {
      toast.error('Помилка при пошуку аналогів')
    }
  })

  const getBrands = useMutation(api.getBrandsByPartNumber, {
    retry: false,
    onSuccess: (data) => {
      setAvailableBrands(data?.data)
    },
    onError: () => {
      toast.error('Помилка при пошуку брендів')
    }
  })

  const resetSearch = () => {
    setAvailableBrands([])
    setSearchParams({})
    setAnalogs([])
  }

  useEffect(() => {
    if (!availableBrands.length) {
      setActiveBrand('')
    }
  }, [availableBrands, setActiveBrand])

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      partNumber: ''
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      resetSearch()
      getBrands.mutate(values.partNumber)
    }
  })

  useEffect(() => {
    const brand = searchParams.get('brand') || ''
    const partNumber = searchParams.get('partNumber') || ''
    if (Boolean(brand) && Boolean(partNumber)) {
      setActiveBrand(brand)
      setFieldValue('partNumber', partNumber)
      getBrands.mutate(partNumber)
      getAnalogs.mutate({
        brand,
        partNumber
      })
    } else if (Boolean(partNumber)) {
      setFieldValue('partNumber', partNumber)
      getBrands.mutate(partNumber)
    }
  }, [])

  const handleFieldChange = (value: string) => {
    handleChange(value)
    resetSearch()
    getBrands.reset()
  }

  const onBrandSelect = (name: string) => {
    if (name && values?.partNumber) {
      setActiveBrand(name)
      setSearchParams(() => ({ brand: name, partNumber: values.partNumber }))
      getAnalogs.mutate({ brand: name, partNumber: values.partNumber })
    }
  }

  return (
    <Box width="100%">
      {Boolean(getAnalogs.isLoading || getBrands.isLoading) && <Loader />}
      <StyledForm onSubmit={handleSubmit}>
        <Box flexGrow={1} width="100%">
          <FieldText
            name="partNumber"
            value={values['partNumber']}
            label="Пошук по артикулу. Наприклад: oc90"
            handleChange={handleFieldChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            autoComplete="off"
            sx={{ backgroundColor: 'white' }}
            fullWidth
          />
        </Box>
        <Box pt={2}>
          <StyledSubmit type="submit" variant="contained" fullWidth>
            Пошук
          </StyledSubmit>
        </Box>
      </StyledForm>

      <BrandSelect
        isSuccess={getBrands?.isSuccess}
        activeBrand={activeBrand}
        availableBrands={availableBrands}
        onBrandSelect={onBrandSelect}
      />

      {Boolean(activeBrand && !analogs?.length && !getAnalogs.isLoading) && (
        <Box textAlign="center" my={5}>
          Немає позицій доступних до придбання.
        </Box>
      )}
    </Box>
  )
}

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const StyledSubmit = styled(Button)`
  padding: 15px !important;
`
