import React from 'react'
import { useQuery } from 'react-query'
import { ICurrency } from '../../../types'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import { CurrencyManageItem } from './CurrencyManageItem'

export const CurrencyManage = () => {
  const { data: currencies, isLoading } = useQuery(
    'currencies',
    api.getAllCurrencies
  )

  const list: ICurrency[] = currencies?.data || []

  return (
    <>
      {isLoading && <Loader />}
      {list.map((item) => (
        <CurrencyManageItem item={item} key={'currency-control-' + item.id} />
      ))}
    </>
  )
}
