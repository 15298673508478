import React, { FC, ReactNode, useMemo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Outlet } from 'react-router'
import { Header } from '../components/Header'
import styled from 'styled-components'
import { colors } from '../theme/colors'

interface IProps {
  children?: ReactNode
}

export const AppLayout: FC<IProps> = ({ children }) => {
  const year = useMemo(() => {
    const date = new Date()
    return date.getFullYear()
  }, [])

  return (
    <StyledBox>
      <Box>
        <Box zIndex={1} position="relative">
          <Header />
        </Box>
        {Boolean(children) && children}
        <Box flexGrow={1} zIndex={1} position="relative">
          <Outlet />
        </Box>
      </Box>
      <Footer>
        <Grid container spacing={4} justifyContent="center" maxWidth="1400px">
          <Grid item xs={12} md={6} lg={4}>
            <StyledMap>
              <iframe
                title="address"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7579.5682978450595!2d32.01701180865555!3d49.43177242610218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14d8ac89a4ac5%3A0x673381eef1e3e0c1!2z0JDQstGC0L7QvNCw0LPQsNC30LjQvSDQkNCS0KLQntCa0KDQkNCZ!5e0!3m2!1suk!2sua!4v1678116951977!5m2!1suk!2sua"
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </StyledMap>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ContactsWrapper>
              <Box>
                <Typography variant="h5" fontWeight={500} mb={2}>
                  Контактна інформація
                </Typography>
                <Typography>
                  Адреса: м.Черкаси, вул. Віталія Вергая, 3
                </Typography>
                <Typography>
                  тел.:{' '}
                  <StyledPhone href="tel:+380685855953">
                    +38 (068) 585-59-53
                  </StyledPhone>
                </Typography>
                <Typography mt={1} fontWeight={500}>
                  Графік роботи:
                </Typography>
                <Typography>
                  Пн-Пт: 09:00 - 18:00 <br />
                  Сб: 09:00 - 12:00 <br />
                  Нд: Вихідний
                </Typography>
              </Box>
              <Typography fontWeight={600} mt={5}>
                Created by Yurii Khodzitskyi © {year}
              </Typography>
            </ContactsWrapper>
          </Grid>
        </Grid>
      </Footer>
    </StyledBox>
  )
}

const StyledBox = styled(Box)`
  min-height: calc(100vh + 1px);
  display: flex;
  flex-direction: column;
  justify-items: space-between;
`

const Footer = styled(Box)`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
`

const StyledMap = styled(Box)`
  iframe {
    height: 300px;
    width: 100%;
  }
`

const ContactsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const StyledPhone = styled.a`
  color: ${colors.gray2};
  text-decoration: underline;
`
