import React, { FC, useMemo, useState } from 'react'
import { FieldAutocomplete } from '../../../common'
import { api } from '../../../../utils/api'
import { IPart } from '../../../../types'
import { useMutation, useQueryClient } from 'react-query'
import { Box } from '@mui/material'

interface IAddCross {
  setAddCrossOpen: (p: boolean) => void
  defaultId?: string
}

export const AddCross: FC<IAddCross> = ({ defaultId, setAddCrossOpen }) => {
  const [options, setOptions] = useState<IPart[]>([])
  const queryClient = useQueryClient()
  const { mutate: searchData } = useMutation(api.searchPartByCode, {
    onSuccess: (resp) => {
      if (resp?.data?.data) setOptions(resp?.data?.data)
    },
    retry: false
  })
  const { mutate: addCross } = useMutation(api.addPartsCross, {
    onSuccess: () => {
      queryClient.invalidateQueries(['part_details'])
    },
    retry: false
  })

  const searchOptions = useMemo(() => {
    if (options)
      return options.map((item: IPart) => ({
        label: `${item.partNumber} - ${item.brand.name}`,
        id: item.id
      }))
    return []
  }, [options])

  return (
    <Box maxWidth="400px">
      <FieldAutocomplete
        defaultValue={''}
        label={'Пошук'}
        name={'crossSearch'}
        handleChange={(e) => {
          if (defaultId && e?.id && defaultId !== e?.id)
            addCross({ partId_1: defaultId, partId_2: e?.id })
          setAddCrossOpen(false)
        }}
        handleBlur={() => {}}
        onInputChange={(e: any) => {
          const value: string = e.target.value
          if (value.length > 2) {
            searchData({ searchText: value, brandId: '', page: 1, offset: 100 })
          } else if (value === '') {
            setOptions([])
          }
        }}
        errors={[]}
        touched={[]}
        options={searchOptions}
        clearOnBlur={true}
      />
    </Box>
  )
}
