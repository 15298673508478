import React from 'react'
import { AppLayout } from '../layouts/AppLayout'
import { Navigate, Route, Routes } from 'react-router'
import {
  MainPage,
  LoginPage,
  RegistrationPage,
  ProvidersPage,
  PartsPage
} from '../pages'
import { AdminLayout } from '../layouts/AdminLayout'
import { ShouldBeAdmin, ShouldBeLoggedIn, ShouldNotBeLoggedIn } from './guards'
import { Page404 } from '../pages/Page404/Page404'
import { DashboardPage } from '../pages/DashboardPage/DashboardPage'
import { ProviderUploadSettingsPage } from '../pages/ProviderUploadSettingsPage/ProviderUploadSettingsPage'
import { ProviderUploadRemainsPage } from '../pages/ProviderUploadRemainsPage/ProviderUploadRemainsPage'
import { UsersPage } from '../pages/UsersPage/UsersPage'
import { OrdersPage } from '../pages/OrdersPage/OrdersPage'
import { OrderDetailsPage } from '../pages/OrdersPage/OrderDetailsPage'
import { UserOrdersPage } from '../pages/UserOrdersPage/UserOrdersPage'
import { BrandsPage } from '../pages/Brands/BrandsPage'
import { UserSettingsPage } from '../pages/UserSettingsPage/UserSettingsPage'
import { ChangePasswordPage } from '../pages/UserSettingsPage/ChangePasswordPage'
import { LogsPage } from '../pages/LogsPage/LogsPage'
import { ForgotPasswordPage } from '../pages/ForgotPassword/ForgotPasswordPage'
import { PartsDetailsPage } from '../pages/PartsPage/PartsDetailsPage'
import { PageSearchVIN } from '../pages/PageSearchVIN/PageSearchVIN'
import { PageSearchVINResult } from '../pages/PageSearchVIN/PageSearchVINResult'
import { PageCatalogueGroupsSingle } from '../pages/PageSearchVIN/PageCatalogueGroupsSingle'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<MainPage />} />
        <Route
          path="login"
          element={
            <ShouldNotBeLoggedIn>
              <LoginPage />
            </ShouldNotBeLoggedIn>
          }
        />
        <Route
          path="registration"
          element={
            <ShouldNotBeLoggedIn>
              <RegistrationPage />
            </ShouldNotBeLoggedIn>
          }
        />
        <Route path="*" element={<Page404 />} />
        <Route
          path="orders"
          element={
            <ShouldBeLoggedIn>
              <UserOrdersPage />
            </ShouldBeLoggedIn>
          }
        />
        <Route
          path="settings"
          element={
            <ShouldBeLoggedIn>
              <UserSettingsPage />
            </ShouldBeLoggedIn>
          }
        />
        <Route
          path="change-password"
          element={
            <ShouldBeLoggedIn>
              <ChangePasswordPage />
            </ShouldBeLoggedIn>
          }
        />
        <Route
          path="forgot-password"
          element={
            <ShouldNotBeLoggedIn>
              <ForgotPasswordPage />
            </ShouldNotBeLoggedIn>
          }
        />
        <Route
          path="search-vin"
          element={
            <ShouldBeLoggedIn>
              <PageSearchVIN />
            </ShouldBeLoggedIn>
          }
        />
        <Route
          path="/search-vin-result/:catalog/:vehicleid/:ssd"
          element={
            <ShouldBeLoggedIn>
              <PageSearchVINResult />
            </ShouldBeLoggedIn>
          }
        />
        <Route
          path="/catalogue-group/:catalog/:group/:vehicleid/:ssd"
          element={
            <ShouldBeLoggedIn>
              <PageCatalogueGroupsSingle />
            </ShouldBeLoggedIn>
          }
        />
      </Route>
      <Route
        path="/admin"
        element={
          <ShouldBeAdmin>
            <AdminLayout />
          </ShouldBeAdmin>
        }>
        <Route index element={<Navigate to="/admin/dashboard" />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="providers" element={<ProvidersPage />} />
        <Route
          path="providers/:id/upload-settings"
          element={<ProviderUploadSettingsPage />}
        />
        <Route
          path="providers/:id/upload-remains"
          element={<ProviderUploadRemainsPage />}
        />
        <Route path="users" element={<UsersPage />} />
        <Route path="orders" element={<OrdersPage />} />
        <Route path="orders/:id" element={<OrderDetailsPage />} />
        <Route path="brands" element={<BrandsPage />} />
        <Route path="parts" element={<PartsPage />} />
        <Route path="parts/:id" element={<PartsDetailsPage />} />
        <Route path="logs" element={<LogsPage />} />
      </Route>
    </Routes>
  )
}
