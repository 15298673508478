import React from 'react'
import { useFormik } from 'formik'
import { Box, Button, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { toast } from 'react-toastify'
import { changePasswordValidationSchema } from './validation'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FieldPassword } from '../common'

export const ChangePassword = () => {
  const queryClient = useQueryClient()

  const changePassword = useMutation(api.changePassword, {
    retry: false,
    onSuccess: () => {
      toast.success('Успішно!')
      queryClient.invalidateQueries('me')
    }
  })

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        oldPassword: '',
        password: ''
      },
      validationSchema: changePasswordValidationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        changePassword.mutate(values)
      }
    })

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Typography
        variant="h4"
        textAlign="center"
        fontWeight={500}
        paddingBottom="20px"
        lineHeight={0.95}>
        Зміна паролю
      </Typography>

      <FieldPassword
        name="oldPassword"
        value={values['oldPassword']}
        label="Старий Пароль"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldPassword
        name="password"
        value={values['password']}
        label="Новий Пароль"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <Link to="/settings">Інші налаштування</Link>

      <Box mt={6}>
        <StyledSubmit type="submit" variant="outlined" fullWidth>
          Зберегти зміни
        </StyledSubmit>
      </Box>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  padding: 50px 30px;
  border-radius: 30px;
  flex-direction: column;
  margin: 40px 20px;
  border: 1px solid #c5c5c5;
`

const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
