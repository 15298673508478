import React, { useEffect, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { EditPartModal, PartsList, PartsSearch } from '../../components'
import { ICreatePartBody, IPartsSearchParams } from '../../types'
import { useSearchParams } from 'react-router-dom'

export const PartsPage = () => {
  const queryClient = useQueryClient()
  const [queryParams, setQueryParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)
  const [page, setPage] = useState(() => {
    const queryPage = Number(queryParams.get('page') || '1')
    return Number(queryPage)
  })
  const [searchParams, setSearchParams] = useState<IPartsSearchParams>(() => {
    const brand = queryParams.get('brand') || ''
    const searchText = queryParams.get('searchText') || ''
    const queryPage = Number(queryParams.get('page') || '1')

    return {
      searchText,
      brandId: brand,
      page: queryPage || page,
      offset: 50
    }
  })

  const [defaultValues, setDefaultValues] = useState<
    ICreatePartBody | undefined
  >(undefined)

  const { mutate: createPart } = useMutation(api.createPart, {
    retry: false,
    onSuccess: (data) => {
      console.log(data?.data)
      queryClient.invalidateQueries('parts')
      queryClient.invalidateQueries('search-by-code')
    }
  })
  const { mutate: editPart } = useMutation(api.editPart, {
    retry: false,
    onSuccess: (data) => {
      console.log(data?.data)
      queryClient.invalidateQueries('parts')
      queryClient.invalidateQueries('search-by-code')
    }
  })
  const { data: searchData } = useQuery(
    ['parts', searchParams],
    () => api.searchPartByCode(searchParams),
    {
      retry: false
    }
  )

  useEffect(() => {
    if (Boolean(defaultValues) && !isOpen) setIsOpen(true)
  }, [defaultValues, isOpen])

  useEffect(() => {
    const brand = queryParams.get('brand') || ''
    const searchText = queryParams.get('searchText') || ''
    setQueryParams(() => ({ brand, searchText, page: page.toString() }))
    setSearchParams((prev) => ({ ...prev, page }))
  }, [page, queryParams, setQueryParams])

  const onCreatePart = (values: ICreatePartBody) => {
    if (values?.id) {
      editPart(values)
    } else {
      createPart(values)
    }

    setIsOpen(false)
    setDefaultValues(undefined)
    queryClient.invalidateQueries('parts')
  }

  return (
    <>
      <EditPartModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        defaultValues={
          defaultValues || {
            partNumber: '',
            name: '',
            brandId: '',
            crossId: undefined,
            id: undefined
          }
        }
        onSubmit={onCreatePart}
        setDefaultValues={setDefaultValues}
      />
      <Box mb={2} display="flex" gap={2}>
        <Typography fontSize="25px" fontWeight={500}>
          Номенклатура
        </Typography>
        <Button variant="contained" onClick={() => setIsOpen(true)}>
          Створити
        </Button>
      </Box>
      <PartsSearch
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setPage={setPage}
      />
      <PartsList
        searchData={searchData?.data || { data: [], count: 0 }}
        page={page}
        setPage={setPage}
        setDefaultValues={setDefaultValues}
      />
    </>
  )
}
