import React, { FC } from 'react'
import { ICatalogueSearchVinResponse } from '../../types'
import { Box, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { colors } from '../../theme'
import styled from 'styled-components'
import InfoIcon from '@mui/icons-material/Info'
import { DescriptionTable } from './DescriptionTable'

interface ICatalogueSearchResults {
  list: ICatalogueSearchVinResponse[]
}

export const CatalogueSearchResults: FC<ICatalogueSearchResults> = ({
  list
}) => {
  return (
    <>
      {list.map((item) => (
        <Box
          key={'search-vin' + item?.ssd}
          py={1}
          display="flex"
          alignItems="center"
          sx={{
            backgroundColor: '#FFF'
          }}>
          <StyledLink
            to={`/search-vin-result/${item?.catalog}/${item?.vehicleid}/${item?.ssd}`}>
            {item?.brand}; {item?.name}; Двигун -{' '}
            {item?.attributes?.engine?.value}; КП -{' '}
            {item?.attributes?.transmission?.value}
          </StyledLink>
          <Box ml={2}>
            <StyledTooltip
              title={
                <DescriptionTable
                  descriptionItems={item?.attributes?.description || []}
                />
              }>
              <InfoIcon />
            </StyledTooltip>
          </Box>
        </Box>
      ))}
    </>
  )
}

const StyledLink = styled(Link)`
  color: ${colors.dark1};

  &:hover {
    text-decoration: underline;
  }
`

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`
