import React, { FC, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import { IPart } from '../../types/search'
import styled from 'styled-components'
import { StyledTableCell } from '../admin/Providers/ProvidersList'

import { AddCart } from './AddCart'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { Images } from './Images'
import { useQuery } from 'react-query'
import { api } from '../../utils/api'
import { deleteCookie } from '../../utils/cookies'
import { getDelivery } from '../../utils/getDeliveryDate'

interface IPartsList {
  list: IPart[]
  activeBrand: string
}

export const PartsList: FC<IPartsList> = ({ list, activeBrand }) => {
  const [expanded, setExpanded] = useState('')
  const [cookies] = useCookies(['auth'])
  const { data: me, isLoading: isUserLoading } = useQuery('me', api.getMe, {
    enabled: Boolean(cookies?.auth),
    onError: () => deleteCookie('auth', { path: '/' })
  })
  const today = new Date()

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '')
  }

  const getPrice = useCallback(
    (price: number) => {
      if (isUserLoading) {
        return <>Завантаження...</>
      }

      if (me?.data?.info_price_available && me?.data?.info_price_percents) {
        const infoPrice = Math.ceil(
          price * (1 + me?.data?.info_price_percents / 100)
        )
        return (
          <span>
            <Tooltip title={`${price} грн.`}>
              <span>{infoPrice} грн.</span>
            </Tooltip>
          </span>
        )
      }
      return <span>{price} грн.</span>
    },
    [
      me?.data?.info_price_available,
      me?.data?.info_price_percents,
      isUserLoading
    ]
  )

  const clonedList = cloneDeep(list)

  const firstElem =
    clonedList[0]?.displayBrand === activeBrand ? clonedList.splice(0, 1) : []

  const sortedByLowestPrice = clonedList.sort((c, d) => {
    const sortedStockC = c.stock.sort(
      (a, b) => a.price - b.price || Number(a.delivery) - Number(b.delivery)
    )
    const sortedStockD = d.stock.sort(
      (a, b) => a.price - b.price || Number(a.delivery) - Number(b.delivery)
    )
    const lowestPriceC = sortedStockC[0]?.price
    const lowestPriceD = sortedStockD[0]?.price

    return lowestPriceC - lowestPriceD
  })

  const sorted = [...firstElem, ...sortedByLowestPrice]

  return (
    <div>
      {!Boolean(cookies['auth']) && (
        <WarningMessage>
          Для додаткової знижки{' '}
          <MessageLink to="registration">зареєструйтесь</MessageLink>, або{' '}
          <MessageLink to="login">увійдіть</MessageLink> в свій обліковий запис.
        </WarningMessage>
      )}
      {sorted.map((part) => {
        const { displayBrand, article, title, images, stock, name } = part
        const sortedStock = stock.sort(
          (a, b) => a.price - b.price || Number(a.delivery) - Number(b.delivery)
        )
        const lowestPrice = getPrice(sortedStock[0]?.price)

        return (
          <StyledAccordion
            expanded={expanded === `${displayBrand}-${article}`}
            onChange={handleChange(`${displayBrand}-${article}`)}
            TransitionProps={{ unmountOnExit: true }}
            key={displayBrand + article + title}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${displayBrand}-${article}-content`}
              id={`${displayBrand}-${article}-header`}>
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={6} sm={4}>
                  <Typography>{displayBrand}</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography sx={{ color: 'text.secondary' }}>
                    {article}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ItemName>{title || name || 'Без імені'}</ItemName>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography>Ціна від:</Typography>
                  <Typography fontWeight={500}>{lowestPrice}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {Boolean(images?.length) && <Images list={images || []} />}
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 400 }}
                  size="small"
                  aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Доставка</StyledTableCell>
                      <StyledTableCell align="center">
                        Доступно (шт.)
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Ціна (грн)
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Додати в корзину
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(stock) &&
                      sortedStock.map((item) => (
                        <TableRow
                          key={'part' + item.id + item.price}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0
                            }
                          }}>
                          <StyledTableCell align="center">
                            <span>
                              {getDelivery(item.delivery, item.orderBy, today)}
                            </span>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Tooltip
                              title={`Останнє оновлення: ${moment(
                                item.createdAt
                              ).format('DD.MM.YYYY HH:mm')}`}>
                              <span>{item.count}</span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              gap={1}>
                              <Typography fontWeight={500}>
                                {getPrice(item.price)}
                              </Typography>
                              {item?.unreturnable && (
                                <Tooltip title="Без можливості повернення!">
                                  <ErrorOutlinedIcon color="error" />
                                </Tooltip>
                              )}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center">
                              {Boolean(cookies['auth']) ? (
                                <AddCart part={part} stock={item} />
                              ) : (
                                <Link to="/login">Увійти</Link>
                              )}
                            </Box>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </StyledAccordion>
        )
      })}
    </div>
  )
}

const StyledAccordion = styled(Accordion)`
  background-color: #f5feff !important;

  & .MuiAccordionSummary-root {
    user-select: initial;
  }
`

const WarningMessage = styled(Box)`
  background-color: #fffec6;
  padding: 15px 25px;
  font-weight: 500;
  font-size: 14px;
  margin: -25px 0 25px;
`

const MessageLink = styled(Link)`
  color: blue;
`

const ItemName = styled(Typography)`
  word-break: break-word;
`
