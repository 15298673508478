import React, { FC } from 'react'
import { CustomModal, FieldSelect, FieldText } from '../../common'
import { Box, Button } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { Currencies, IProvider } from '../../../types'
import styled from 'styled-components'
import { addProviderValidationSchema } from './validations'
import { FieldCheckbox } from '../../common/FieldCheckbox'

interface IAddProviderModal {
  defaultValues?: IProvider
  isOpen: boolean
  setIsOpen: (p: boolean) => void
  setDefaultValues: (p: IProvider | undefined) => void
}

const currenciesOptions = [
  { title: 'UAH', value: Currencies.UAH },
  { title: 'EUR', value: Currencies.EUR },
  { title: 'USD', value: Currencies.USD }
]

export const AddProviderModal: FC<IAddProviderModal> = ({
  defaultValues,
  isOpen,
  setIsOpen,
  setDefaultValues
}) => {
  const queryClient = useQueryClient()
  const addProvider = useMutation(api.addProvider, {
    onSuccess: () => {
      toast.success('Added Provider')
      handleClose()
      queryClient.invalidateQueries('providers')
    }
  })
  const updateProvider = useMutation(api.updateProvider, {
    onSuccess: () => {
      toast.success('Added Provider')
      handleClose()
      queryClient.invalidateQueries('providers')
    }
  })

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: defaultValues || {
      name: '',
      currency: Currencies.UAH,
      multiplier: 1.2,
      unreturnable: false
    },
    validationSchema: addProviderValidationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (Boolean(defaultValues)) {
        updateProvider.mutate({ ...values })
      } else {
        addProvider.mutate(values)
      }
    }
  })

  const handleClose = () => {
    resetForm()
    setIsOpen(false)
    setDefaultValues(undefined)
  }

  return (
    <>
      <Button variant="contained" onClick={() => setIsOpen(true)}>
        Add Provider
      </Button>
      <CustomModal isOpen={isOpen} handleClose={handleClose}>
        <StyledForm onSubmit={handleSubmit}>
          <FieldText
            name="name"
            value={values['name']}
            label="Імʼя"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldText
            name="multiplier"
            type="number"
            inputProps={{
              step: '0.01'
            }}
            value={values['multiplier']}
            label="Множник(націнка)"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldSelect
            name="currency"
            value={values['currency']}
            label="Валюта"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            options={currenciesOptions}
            fullWidth
          />
          <FieldCheckbox
            name="unreturnable"
            value={values['unreturnable']}
            label="Без повернення"
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
          <Box mt={6}>
            <StyledSubmit type="submit" variant="contained" fullWidth>
              Submit
            </StyledSubmit>
          </Box>
        </StyledForm>
      </CustomModal>
    </>
  )
}

const StyledForm = styled.form`
  width: 400px;
  flex-direction: column;
`
const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
