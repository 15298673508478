import React, { FC, useMemo } from 'react'
import { Box, Button, createFilterOptions } from '@mui/material'
import styled from 'styled-components'
import { FieldAutocomplete, FieldText, Loader } from '../../common'
import { useFormik } from 'formik'
import { useQuery } from 'react-query'
import { api } from '../../../utils/api'
import { IBrand, IPartsSearchParams } from '../../../types'
import { useSearchParams } from 'react-router-dom'

interface IPartsSearch {
  searchParams: IPartsSearchParams
  setSearchParams: (searchParams: IPartsSearchParams) => any
  setPage: (p: number) => void
}

const filter = createFilterOptions()

export const PartsSearch: FC<IPartsSearch> = ({
  searchParams,
  setSearchParams,
  setPage
}) => {
  const [, setQueryParams] = useSearchParams()
  const { data: brandsData, isLoading: BrandsLoading } = useQuery(
    'brands',
    () => api.getBrands()
  )

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      searchText: searchParams.searchText || '',
      brandId: searchParams.brandId || ''
    },
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: ({ searchText, brandId }) => {
      setSearchParams({ ...searchParams, page: 1, searchText, brandId })
      setQueryParams(() => ({ brand: brandId, searchText, page: '1' }))
      setPage(1)
    }
  })

  const brandsOptions = useMemo(() => {
    if (brandsData?.data)
      return brandsData?.data.map((item: IBrand) => ({
        label: item.name,
        id: item.id
      }))
    return []
  }, [brandsData?.data])

  const defaultBrand =
    brandsOptions.find((option: any) => {
      return option.id === Number(searchParams?.brandId)
    }) || ''

  const handleBrandSelect = (value: any) => {
    if (value?.id) {
      setFieldValue('brandId', value.id)
    } else {
      setFieldValue('brandId', '')
    }
  }

  if (BrandsLoading) {
    return <Loader />
  }

  return (
    <>
      {BrandsLoading && <Loader />}
      <StyledForm onSubmit={handleSubmit}>
        <Box
          sx={{
            width: { xs: '100%' },
            maxWidth: { md: '220px' }
          }}>
          <FieldAutocomplete
            defaultValue={defaultBrand?.label || ''}
            label={'Brand'}
            name={'brandId'}
            handleChange={handleBrandSelect}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            options={brandsOptions}
            isOptionEqualToValue={(option: any, value: any) =>
              option?.label === value?.label
            }
            filterOptions={(options: any, params: any) =>
              filter(options, params)
            }
            clearOnBlur={true}
          />
        </Box>

        <FieldText
          name="searchText"
          value={values['searchText']}
          label="Пошук по номеру"
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          autoComplete="off"
        />
        <StyledSubmit type="submit" variant="outlined">
          Знайти
        </StyledSubmit>
      </StyledForm>
    </>
  )
}

const StyledForm = styled.form`
  width: 100%;
  display: flex;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    .MuiTextField-root {
      width: 100%;
    }
  }
`

const StyledSubmit = styled(Button)`
  height: 56px;
  margin-top: 16px !important;
  padding: 13px !important;
`
