import { createTheme } from '@mui/material'
import { colors } from './colors'

export const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        color: colors.dark1
      }
    }
  }
})
