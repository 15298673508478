import React from 'react'
import { useFormik } from 'formik'
import { FieldText } from '../common'
import { Box, Button, Typography } from '@mui/material'
import { useMutation } from 'react-query'
import { api } from '../../utils/api'
import { toast } from 'react-toastify'
import { validationSchema } from './validation'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { FieldCheckbox } from '../common/FieldCheckbox'
import { FieldPassword } from '../common'

export const RegistrationForm = () => {
  const navigate = useNavigate()
  const registrationMutation = useMutation(api.register, {
    onSuccess: () => {
      navigate('/login')
      toast.success('Успішно! Тепер ви можете зайти в свій обліковий запис.')
    }
  })

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
        passwordRepeat: '',
        name: '',
        city: '',
        address: '',
        phone: '',
        agreements: false
      },
      validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        registrationMutation.mutate({
          ...values,
          email: values.email.toLowerCase()
        })
      }
    })

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Typography
        variant="h3"
        textAlign="center"
        fontWeight={500}
        paddingBottom="20px"
        lineHeight={0.95}>
        АВТО <br /> КРАЙ
      </Typography>

      <Typography>Реєстрація.</Typography>
      <FieldText
        name="email"
        value={values['email']}
        label="Email"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldText
        name="name"
        value={values['name']}
        label="Імʼя, Прізвище"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldText
        name="phone"
        value={values['phone']}
        label="Номер Телефону"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldText
        name="city"
        value={values['city']}
        label="Місто"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldText
        name="address"
        value={values['address']}
        label="Адреса (не обовʼязково)"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldPassword
        name="password"
        value={values['password']}
        label="Пароль"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldPassword
        name="passwordRepeat"
        value={values['passwordRepeat']}
        label="Повторіть пароль"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      <FieldCheckbox
        name="agreements"
        value={values['agreements']}
        label="Я погоджуюсь на обробку моїх персональних даних."
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
      />
      <Box mt={3}>
        <Typography>
          Вже маєте обліковий запис? <Link to="/login">Увійти.</Link>
        </Typography>
      </Box>

      <Box mt={6}>
        <StyledSubmit type="submit" variant="outlined" fullWidth>
          Зареєструвати Аккаунт
        </StyledSubmit>
      </Box>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  padding: 50px 30px;
  border-radius: 30px;
  flex-direction: column;
  margin: 40px 20px;
  border: 1px solid #c5c5c5;
  background-color: #fff;
`

const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
