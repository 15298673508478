import React from 'react'
import { Box } from '@mui/material'
import { NewPasswordForm, ResetPasswordForm } from '../../components'
import { useSearchParams } from 'react-router-dom'

export const ForgotPasswordPage = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  return (
    <Box px={3} py={6} display="flex" justifyContent="center">
      {token ? <NewPasswordForm /> : <ResetPasswordForm />}
    </Box>
  )
}
