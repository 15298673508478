import React, { FC, useMemo } from 'react'
import { Box, Button, createFilterOptions } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { addSettingValidationSchema } from './validations'
import { CustomModal, FieldText, Loader } from '../../common'
import styled from 'styled-components'
import { IBrand, IUploadSettingsRequest } from '../../../types'
import { useParams } from 'react-router'
import { FieldAutocomplete } from '../../common/FieldAutocomplete'

interface IUploadSettingModal {
  defaultValues?: IUploadSettingsRequest
  isOpen: boolean
  setIsOpen: (p: boolean) => void
  setDefaultValues: (p: IUploadSettingsRequest | undefined) => void
}

const filter = createFilterOptions()

export const UploadSettingModal: FC<IUploadSettingModal> = ({
  defaultValues,
  isOpen,
  setIsOpen,
  setDefaultValues
}) => {
  const queryClient = useQueryClient()
  const addSetting = useMutation(api.addUploadSetting, {
    onSuccess: () => {
      handleClose()
      queryClient.invalidateQueries('uploadSettings')
    }
  })
  const editSetting = useMutation(api.editUploadSetting, {
    onSuccess: () => {
      handleClose()
      queryClient.invalidateQueries('uploadSettings')
    }
  })
  const { id: providerId } = useParams()
  const { data: brandsData, isLoading: BrandsLoading } = useQuery(
    'brands',
    () => api.getBrands()
  )

  const addBrand = useMutation(api.addBrand, {
    onSuccess: (resp: any) => {
      toast.success('Added Brand ' + resp?.data?.name)
      queryClient.invalidateQueries('brands')
      handleClose()
    }
  })

  const brandsOptions = useMemo(() => {
    if (brandsData?.data)
      return brandsData?.data.map((item: IBrand) => ({
        label: item.name,
        id: item.id
      }))
    return []
  }, [brandsData?.data])

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: defaultValues || {
      brand: '',
      brand_file: '',
      prefix_add: '',
      prefix_remove: '',
      suffix_add: '',
      suffix_remove: '',
      provider: providerId || ''
    },
    validationSchema: addSettingValidationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (providerId) {
        if (Boolean(defaultValues)) {
          editSetting.mutate({ ...values })
        } else {
          addSetting.mutate(values)
        }
      } else {
        toast.error('Wrong provider Id')
      }
    }
  })

  const handleBrandSelect = (value: any) => {
    if (value?.id) {
      setFieldValue('brand', value.id)
    } else if (value?.inputValue) {
      addBrand.mutate({ data: value.inputValue })
    }
  }

  const handleClose = () => {
    resetForm()
    setIsOpen(false)
    setDefaultValues(undefined)
  }

  const defaultBrand =
    brandsOptions.find((option: any) => {
      return option.id === defaultValues?.brand
    }) || ''

  if (BrandsLoading) {
    return <Loader />
  }

  return (
    <>
      <CustomModal isOpen={isOpen} handleClose={handleClose}>
        <StyledForm onSubmit={handleSubmit}>
          <FieldAutocomplete
            defaultValue={defaultBrand?.label}
            label={'Brand'}
            name={'brand'}
            handleChange={handleBrandSelect}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            options={brandsOptions}
            isOptionEqualToValue={(option: any, value: any) =>
              option?.label === value
            }
            filterOptions={(options: any, params: any) => {
              const filtered = filter(options, params)

              const { inputValue } = params
              const isExisting = options.some(
                (option: any) => inputValue?.toUpperCase() === option?.label
              )

              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `Create: ${inputValue}`
                })
              }

              return filtered
            }}
          />
          <FieldText
            name="brand_file"
            value={values['brand_file']}
            label="Brand File*"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldText
            name="prefix_add"
            value={values['prefix_add']}
            label="Prefix Add"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldText
            name="prefix_remove"
            value={values['prefix_remove']}
            label="Prefix Remove"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldText
            name="suffix_add"
            value={values['suffix_add']}
            label="Suffix Add"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldText
            name="suffix_remove"
            value={values['suffix_remove']}
            label="Suffix Remove"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <Box mt={6}>
            <StyledSubmit type="submit" variant="contained" fullWidth>
              Submit
            </StyledSubmit>
          </Box>
        </StyledForm>
      </CustomModal>
    </>
  )
}

const StyledForm = styled.form`
  width: 400px;
  flex-direction: column;
`
const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
