import React from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import { Box, Divider, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { CrossList } from './crosses/CrossList'
import styled from 'styled-components'

export const PartDetails = () => {
  const { id } = useParams()
  const { data, isLoading } = useQuery([`part_details`, id], () =>
    api.getPartsById(id || '')
  )

  return (
    <>
      {isLoading && <Loader />}
      <Box mb={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography fontSize="25px" fontWeight={500}>
            {data?.data?.partNumberDirty} - {data?.data?.brand?.name}
          </Typography>
          <StyledSearchLink
            to={`/?brand=${data?.data?.brand?.name}&partNumber=${data?.data?.partNumber}`}>
            <SearchIcon color="info" />
          </StyledSearchLink>
        </Box>

        {data?.data?.name}
      </Box>

      <Divider />
      <CrossList
        crosses={data?.data?.crosses || []}
        defaultId={data?.data?.id}
      />
    </>
  )
}

const StyledSearchLink = styled(Link)`
  line-height: 0;
`
