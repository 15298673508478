import React, { FC } from 'react'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'

interface IProps {
  value: boolean
  label?: string
  name: string
  handleChange: (value: any) => void
  handleBlur: (value: any) => void
  errors: any
  touched: any
  [key: string]: any
}

export const FieldCheckbox: FC<IProps> = ({
  value,
  label,
  name,
  handleChange,
  errors,
  touched,
  handleBlur,
  ...rest
}) => {
  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={value} {...rest} />}
        label={label || ''}
        name={name}
        onChange={handleChange}
      />
      {/* eslint-disable-next-line react/jsx-no-undef */}
      {Boolean(errors[name] && touched[name]) && (
        <Typography color="red" mt={1} fontSize={14}>
          {errors[name]}
        </Typography>
      )}
    </>
  )
}
