import React, { useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import styled from 'styled-components'
import { colors } from '../../../theme'
import { NavLink } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import GroupsIcon from '@mui/icons-material/Groups'
import RequestPageIcon from '@mui/icons-material/RequestPage'
import BallotIcon from '@mui/icons-material/Ballot'
import { api } from '../../../utils/api'
import { useQuery, useQueryClient } from 'react-query'
import { Roles } from '../../../types'
import { isMobile } from 'react-device-detect'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FactCheckIcon from '@mui/icons-material/FactCheck'

const navLinks = (countUnprocessedOrders: number) => [
  {
    link: '/admin/dashboard',
    title: 'Панель',
    icon: <DashboardIcon fill="#fff" />
  },
  {
    link: '/admin/providers',
    title: 'Постачальники',
    icon: <MoveToInboxIcon fill="#fff" />
  },
  {
    link: '/admin/users',
    title: 'Користувачі',
    icon: <GroupsIcon fill="#fff" />
  },
  {
    link: '/admin/orders',
    title: (
      <>
        Замовлення{' '}
        {Boolean(countUnprocessedOrders) && (
          <OrdersBadge>{countUnprocessedOrders}</OrdersBadge>
        )}
      </>
    ),
    icon: <RequestPageIcon fill="#fff" />
  },
  {
    link: '/admin/parts',
    title: 'Номенклатура',
    icon: <FormatListNumberedIcon fill="#fff" />
  },
  {
    link: '/admin/brands',
    title: 'Бренди',
    icon: <BallotIcon fill="#fff" />
  },
  {
    link: '/admin/logs',
    title: 'Логи',
    icon: <FactCheckIcon fill="#fff" />
  }
]

export const Navigation = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [mobileScrolled, setMobileScrolled] = useState(false)
  const queryClient = useQueryClient()
  const { data } = useQuery('me', api.getMe)
  const { data: countUnprocessedOrders } = useQuery(
    'unprocessedOrders',
    api.countUnprocessedOrders,
    {
      enabled: Boolean(data?.data?.roles === Roles.ADMIN),
      refetchInterval: 60000,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true
    }
  )

  useEffect(() => {
    queryClient.invalidateQueries('orders')
  }, [countUnprocessedOrders?.data])

  useEffect(() => {
    if (mobileOpen) window.scrollTo({ top: 0 })
  }, [mobileOpen])

  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        if (window.pageYOffset > 120) {
          setMobileScrolled(true)
        } else {
          setMobileScrolled(false)
        }
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  if (isMobile && !mobileOpen) {
    return (
      <MobileNavButton top={mobileScrolled ? '0' : '82px'}>
        <IconButton
          onClick={() => setMobileOpen(true)}
          size="small"
          aria-controls={mobileOpen ? 'admin-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={mobileOpen ? 'true' : undefined}>
          <MenuIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
      </MobileNavButton>
    )
  }

  return (
    <StyledNav minWidth={isMobile ? '100vw' : '0'}>
      {isMobile && (
        <Box display="flex" justifyContent="flex-end" pr={2}>
          <IconButton
            onClick={() => setMobileOpen(false)}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={mobileOpen ? 'admin-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={mobileOpen ? 'true' : undefined}>
            <CloseIcon sx={{ width: 32, height: 32, color: '#ffffff' }} />
          </IconButton>
        </Box>
      )}
      {navLinks(countUnprocessedOrders?.data || 0).map(
        ({ link, title, icon }) => (
          <StyledNavItem
            to={link}
            key={'navlink' + link + title}
            onClick={() => setMobileOpen(false)}>
            <IconContainer>{icon}</IconContainer>
            {title}
          </StyledNavItem>
        )
      )}
    </StyledNav>
  )
}

const StyledNav = styled(Box)`
  padding: 20px 0;
  min-height: 100%;
  width: 100%;
  max-width: 300px;
  background-color: ${colors.dark2};
`

const StyledNavItem = styled(NavLink)`
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 30px;
  opacity: 0.8;
  font-size: 18px;

  &.active {
    font-weight: 700;
    opacity: 1;
  }
`
const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 8px;
`
const OrdersBadge = styled(Box)`
  padding: 5px;
  border-radius: 100%;
  background-color: yellow;
  color: #000;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
`

const MobileNavButton = styled(Box)`
  position: fixed;
  right: 0;
  border: 1px solid #000;
  background-color: #f7f7f7;
`
