import React, { useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { IOrderItemsResponse, IOrderResponse, OrderStatus } from '../../types'
import { api } from '../../utils/api'
import { Loader } from '../common'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyledTableCell } from '../admin/Providers/ProvidersList'
import moment from 'moment'

const offset = 25

export const UserOrders = () => {
  const [page, setPage] = useState(1)
  const [expanded, setExpanded] = useState('')
  const { data: myOrders, isLoading } = useQuery(['my-orders', page], () =>
    api.getMyOrders({ page, offset })
  )

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '')
  }

  const list: IOrderResponse[] = myOrders?.data?.data || []

  const count = Math.ceil(myOrders?.data?.count / offset) || 0

  const getStatusText = useCallback((status: OrderStatus) => {
    switch (status) {
      case OrderStatus.INPROGRESS:
        return 'В Обробці'
      case OrderStatus.DONE:
        return 'Оброблено'
      case OrderStatus.REJECTED:
        return 'Відхилено'
      default:
        return ''
    }
  }, [])

  if (isLoading) return <Loader />

  return (
    <Box width="100%">
      <Box my={4} textAlign="center">
        <Typography variant="h4">Мої Замовлення</Typography>
      </Box>

      {list.map((order) => {
        const { comment, createdAt, id, order_items, status } = order

        const sum = order_items
          ?.map(
            (item: IOrderItemsResponse) =>
              Number(item.price) * Number(item.count)
          )
          ?.reduce((acc: number, curr: number) => acc + curr, 0)

        return (
          <StyledAccordion
            expanded={expanded === `${id}`}
            onChange={handleChange(`${id}`)}
            key={`orders-order-${id}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`orders-${id}-content`}
              id={`orders-${id}-header`}>
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={6} sm={3}>
                  <Typography>
                    Номер замовлення: <br />
                  </Typography>
                  <Typography fontWeight="500">{id}</Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  Дата замовлення:
                  <Typography sx={{ color: 'text.secondary' }}>
                    {moment(createdAt).format('DD.MM.YYYY HH:mm')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography>Сума замовлення:</Typography>
                  <Typography fontWeight="500">{sum} грн.</Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography>Статус</Typography>
                  <Typography fontWeight="500">
                    {getStatusText(status)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 700 }}
                  size="small"
                  aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Артикул</StyledTableCell>
                      <StyledTableCell align="center">Бренд</StyledTableCell>
                      <StyledTableCell align="center">
                        Кількість
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Доставка (дн.)
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Ціна за шт. (грн)
                      </StyledTableCell>
                      <StyledTableCell align="center">Сума</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(order_items) &&
                      order_items?.map((item) => (
                        <TableRow
                          key={
                            'order' +
                            item.partNumber +
                            item.brand.name +
                            item.price +
                            item.count +
                            Math.random() * 100
                          }
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}>
                          <StyledTableCell align="center">
                            {item?.partNumber}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.brand?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.delivery}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.price}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {Number(item.count) * Number(item.price)}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {Boolean(comment) && <Box mt={2}>Коментар: {comment}</Box>}
            </AccordionDetails>
          </StyledAccordion>
        )
      })}

      {!Boolean(list.length) ? (
        <Box display="flex" justifyContent="center" mt={6}>
          <Typography fontSize="24px">Список поки порожній</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" my={6}>
          <Pagination
            count={count}
            page={page}
            color="primary"
            onChange={(e, val) => setPage(val)}
          />
        </Box>
      )}
    </Box>
  )
}

const StyledAccordion = styled(Accordion)`
  background-color: #f5feff !important;
  width: 100%;
`
