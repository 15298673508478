import React, { useState } from 'react'
import { UsersList } from '../../components/admin/Users'
import { IUser } from '../../types'
import { Box, Typography } from '@mui/material'
import { EditUserModal } from '../../components/admin/Users/EditUserModal'

export const UsersPage = () => {
  const [defaultValues, setDefaultValues] = useState<IUser | undefined>(
    undefined
  )

  const EditModal = defaultValues ? (
    <EditUserModal
      isOpen={true}
      setIsOpen={() => setDefaultValues(undefined)}
      setDefaultValues={setDefaultValues}
      defaultValues={defaultValues}
    />
  ) : (
    <></>
  )

  return (
    <>
      <Box mb={2}>
        <Typography fontSize="25px" fontWeight={500}>
          Користувачі
        </Typography>
      </Box>
      {EditModal}
      <UsersList setDefaultValues={setDefaultValues} />
    </>
  )
}
