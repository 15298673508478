import React, { FC, useMemo } from 'react'
import { CustomModal, FieldText, Loader } from '../../common'
import { Box, Button, createFilterOptions } from '@mui/material'
import { useQuery } from 'react-query'
import { api } from '../../../utils/api'
import { useFormik } from 'formik'
import { IBrand, ICreatePartBody } from '../../../types'
import styled from 'styled-components'
import { FieldAutocomplete } from '../../common/FieldAutocomplete'

const filter = createFilterOptions()

interface IAddPartModal {
  defaultValues: ICreatePartBody
  isOpen: boolean
  setIsOpen: (p: boolean) => void
  setDefaultValues: (p: ICreatePartBody | undefined) => void
  onSubmit: (p: ICreatePartBody) => void
}

export const EditPartModal: FC<IAddPartModal> = ({
  defaultValues,
  isOpen,
  setIsOpen,
  setDefaultValues,
  onSubmit
}) => {
  const { data: brandsData, isLoading: BrandsLoading } = useQuery(
    'brands',
    () => api.getBrands()
  )

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitCallback(values)
    }
  })

  const submitCallback = (values: ICreatePartBody) => {
    resetForm()
    onSubmit(values)
  }

  const brandsOptions = useMemo(() => {
    if (brandsData?.data)
      return brandsData?.data.map((item: IBrand) => ({
        label: item.name,
        id: item.id
      }))
    return []
  }, [brandsData?.data])

  const defaultBrand =
    brandsOptions.find((option: any) => {
      return option.id === defaultValues?.brandId
    }) || ''

  const handleBrandSelect = (value: any) => {
    if (value?.id) setFieldValue('brandId', value.id)
  }

  const handleClose = () => {
    resetForm()
    setIsOpen(false)
    setDefaultValues(undefined)
  }

  return (
    <>
      {Boolean(BrandsLoading) && <Loader />}
      <CustomModal isOpen={isOpen} handleClose={handleClose}>
        <StyledForm onSubmit={handleSubmit}>
          <FieldText
            name="partNumber"
            value={values['partNumber'] || ''}
            label="Код"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />

          <FieldText
            name="name"
            value={values['name']}
            label="Назва"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />

          <FieldAutocomplete
            defaultValue={defaultBrand?.label}
            label={'Brand'}
            name={'brandId'}
            handleChange={handleBrandSelect}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            options={brandsOptions}
            isOptionEqualToValue={(option: any, value: any) =>
              option?.label === value
            }
            filterOptions={(options: any, params: any) =>
              filter(options, params)
            }
          />

          <Box mt={6}>
            <StyledSubmit type="submit" variant="contained" fullWidth>
              Зберегти
            </StyledSubmit>
          </Box>
        </StyledForm>
      </CustomModal>
    </>
  )
}

const StyledForm = styled.form`
  min-width: 300px;
  flex-direction: column;
`
const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
