import * as Yup from 'yup'
import { Currencies } from '../../../types'

export const addProviderValidationSchema = Yup.object().shape({
  name: Yup.string().required('Обовʼязкове поле.').max(100),
  currency: Yup.mixed<Currencies>()
    .oneOf(Object.values(Currencies))
    .required('Обовʼязкове поле.'),
  multiplier: Yup.number().required('Обовʼязкове поле.').min(0).max(10),
  unreturnable: Yup.boolean()
})
export const addSettingValidationSchema = Yup.object().shape({
  brand: Yup.string().required('Обовʼязкове поле.'),
  brand_file: Yup.string().required('Обовʼязкове поле.'),
  prefix_add: Yup.string().nullable(true),
  prefix_remove: Yup.string().nullable(true),
  suffix_add: Yup.string().nullable(true),
  suffix_remove: Yup.string().nullable(true),
  provider: Yup.string()
})
export const remainsFieldsValidationSchema = Yup.object().shape({
  brand: Yup.string().required('Обовʼязкове поле.'),
  name: Yup.string().required('Обовʼязкове поле.'),
  partNumber: Yup.string().required('Обовʼязкове поле.'),
  price: Yup.string().required('Обовʼязкове поле.')
})
