import * as Yup from 'yup'

export const newPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required('Обовʼязкове поле.')
    .min(6, 'Мінімальна довжина паролю - 6 символів.')
    .max(100, 'Максимальна довжина паролю - 100 символів.'),
  passwordRepeat: Yup.string()
    .required('Обовʼязкове поле.')
    .oneOf([Yup.ref('password'), null], 'Паролі не однакові.')
})

export const resetValidation = Yup.object().shape({
  email: Yup.string()
    .email('Email не валідний.')
    .required('Обовʼязкове поле.')
    .max(100)
})
