import { AxiosResponse } from 'axios'
import { IOrderUpdateRequest, IPagination } from '../../types'
import { ICart } from '../../types/cart'
import { getHeaders } from '../getHeaders'
import { _axios } from './api'

const getAllOrders = async (
  data: IPagination
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`orders`, { headers, params: { ...data } })
}

const getOrderById = async (id: string): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`orders/${id}`, { headers })
}

const deleteOrder = async (id: string): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`orders/${id}`, { headers })
}

const getMyOrders = async (
  data: IPagination
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`orders/my`, { headers, params: { ...data } })
}

const updateOrder = async (
  data: IOrderUpdateRequest
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  if (data?.id) {
    return _axios.patch(`orders/${data?.id}`, data, {
      headers
    })
  } else {
    throw new Error('Order Id is undefined')
  }
}

const submitOrder = async (
  data: ICart
): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`/orders/create`, data, { headers })
}

const countUnprocessedOrders = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`orders/count-unprocessed`, { headers })
}

export const orders = {
  getAllOrders,
  deleteOrder,
  getOrderById,
  submitOrder,
  getMyOrders,
  updateOrder,
  countUnprocessedOrders
}
