import React, { useMemo, useState } from 'react'
import { Grid, Tab, Tabs } from '@mui/material'
import { useParams } from 'react-router-dom'
import { api } from '../../utils/api'
import { useQuery } from 'react-query'
import { CatalogueGroupsNav } from '../../components/Catalogue/Groups/CatalogueGroupsNav'
import { Loader } from '../../components'
import { CatalogueGroupsDetails } from '../../components/Catalogue/Groups/CatalogueGroupsDetails'
import { ICatalogueGroupUnit } from '../../types'

export const PageSearchVINResult = () => {
  const { ssd, catalog, vehicleid } = useParams()
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedSSD, setSelectedSSD] = useState('')
  const [navTab, setNavTab] = useState<'groups' | 'categories'>('groups')
  const { data: groupsList, isFetching } = useQuery(
    ['groups'],
    () =>
      api.getCatalogueGroups({
        ssd: ssd || '',
        catalog: catalog || '',
        vehicleid: vehicleid || ''
      }),
    {
      enabled: navTab === 'groups'
    }
  )
  const { data: categoriesList, isFetching: isCategoriesFetching } = useQuery(
    ['catalogue-categories'],
    () =>
      api.getCatalogueCategories({
        ssd: ssd || '',
        catalog: catalog || '',
        vehicleid: vehicleid || ''
      }),
    {
      enabled: navTab === 'categories'
    }
  )

  const { data: groupResult, isFetching: isResultFetching } = useQuery(
    ['group-results', selectedGroup],
    () =>
      api.getCatalogueGroupResults({
        group: selectedGroup,
        ssd: ssd || '',
        catalog: catalog || '',
        vehicleid: vehicleid || ''
      }),
    { enabled: Boolean(selectedGroup && navTab === 'groups') }
  )

  const { data: unitsResult, isFetching: isUnitsFetching } = useQuery(
    ['units-results', selectedGroup],
    () =>
      api.getUnitsByCategory({
        group: selectedGroup,
        ssd: selectedSSD || '',
        catalog: catalog || '',
        vehicleid: vehicleid || ''
      }),
    { enabled: Boolean(selectedGroup && navTab === 'categories') }
  )

  const unitsLits: ICatalogueGroupUnit[] = useMemo(
    () =>
      groupResult?.data?.reduce<ICatalogueGroupUnit[]>(
        (acc, curr) => [...acc, ...curr.units],
        []
      ) ||
      unitsResult?.data ||
      [],
    [groupResult?.data, unitsResult?.data]
  )

  return (
    <>
      {(isFetching ||
        isResultFetching ||
        isCategoriesFetching ||
        isUnitsFetching) && <Loader />}
      <Grid container width="100%" maxWidth="1400px" m=" 0 auto" mt={3}>
        <Grid item md={4} xs={12}>
          <Tabs
            value={navTab}
            onChange={(e: any, newValue: 'groups' | 'categories') =>
              setNavTab(newValue)
            }
            aria-label="basic tabs example">
            <Tab label="Групи" value={'groups'} />
            <Tab label="Ілюстрації" value={'categories'} />
          </Tabs>
          {navTab === 'groups' && (
            <CatalogueGroupsNav
              list={groupsList?.data || []}
              onGroupSelect={(id) => setSelectedGroup(id)}
            />
          )}
          {navTab === 'categories' && (
            <CatalogueGroupsNav
              list={categoriesList?.data || []}
              onGroupSelect={(id, ssd) => {
                setSelectedGroup(id)
                setSelectedSSD(ssd || '')
              }}
            />
          )}
        </Grid>
        {Boolean(groupResult?.data || unitsResult?.data) && (
          <Grid item md={8} xs={12}>
            <CatalogueGroupsDetails list={unitsLits} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
