import { AxiosResponse } from 'axios'
import { ICurrency } from '../../types'
import { getHeaders } from '../getHeaders'
import { _axios } from './api'

const getAllCurrencies = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`currencies`, { headers })
}

const updateCurrency = async (
  data: ICurrency
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  if (data?.id) {
    return _axios.patch(`currencies/${data?.id}`, data, {
      headers
    })
  } else {
    throw new Error('Currency Id is undefined')
  }
}

export const currenciesApi = {
  updateCurrency,
  getAllCurrencies
}
