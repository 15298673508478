import React from 'react'
import { LoginForm } from '../../components/LoginForm'
import { Box } from '@mui/material'

export const LoginPage = () => {
  return (
    <Box px={3} py={6} display="flex" justifyContent="center">
      <LoginForm />
    </Box>
  )
}
