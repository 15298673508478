import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email не валідний.')
    .required('Обовʼязкове поле.')
    .max(100),
  password: Yup.string()
    .required('Обовʼязкове поле.')
    .min(6, 'Мінімальна довжина паролю - 6 символів.')
    .max(100, 'Максимальна довжина паролю - 100 символів.')
})
