import { AxiosResponse } from 'axios'
import {
  ICreatePartBody,
  IHandleCrossRequest,
  IPart,
  IPartsSearchParams,
  ISearchPartsResponse
} from '../../types'
import { getHeaders } from '../getHeaders'
import { _axios } from './api'

const createPart = async (
  data: ICreatePartBody
): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`/parts/create`, data, { headers })
}

const editPart = async (
  data: ICreatePartBody
): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.patch(`/parts/edit`, data, { headers })
}

const deletePart = async (
  id: string
): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`/parts/${id}`, { headers })
}

const searchPartByCode = async (
  body: IPartsSearchParams
): Promise<AxiosResponse<ISearchPartsResponse> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`/parts/search-by-code`, {
    headers,
    params: body
  })
}

const getPartsById = async (
  id: string
): Promise<AxiosResponse<IPart> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`/parts/${id}`, {
    headers
  })
}

const addPartsCross = async ({
  partId_1,
  partId_2
}: IHandleCrossRequest): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`/parts/add-cross`, { partId_1, partId_2 }, { headers })
}

const removePartsCross = async ({
  partId_1,
  partId_2
}: IHandleCrossRequest): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`/parts/remove-cross`, { partId_1, partId_2 }, { headers })
}

export const partsApi = {
  createPart,
  editPart,
  deletePart,
  searchPartByCode,
  getPartsById,
  addPartsCross,
  removePartsCross
}
