import { AxiosResponse } from 'axios'
import { getHeaders } from '../getHeaders'
import { IProvider, IRemainsFields } from '../../types'
import { _axios } from './api'

const getAllProviders = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`providers`, { headers })
}

const getProviderById = async (
  id: string
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`providers/${id}`, { headers })
}

const addProvider = async (
  data: IProvider
): Promise<AxiosResponse<IProvider> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`providers/add`, data, { headers })
}

const deleteProvider = async (
  id: string
): Promise<AxiosResponse<IRemainsFields> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`providers/${id}`, {
    headers
  })
}

const updateProvider = async (
  data: IProvider
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  if (data?.id) {
    return _axios.patch(`providers/${data?.id}`, data, {
      headers
    })
  } else {
    throw new Error('Provider Id is undefined')
  }
}

const providers = {
  getAllProviders,
  addProvider,
  deleteProvider,
  updateProvider,
  getProviderById
}

export { providers }
