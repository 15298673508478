import React from 'react'
import { OrderDetails } from '../../components/admin/Orders/OrderDetails'

export const OrderDetailsPage = () => {
  return (
    <>
      <OrderDetails />
    </>
  )
}
