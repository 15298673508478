import React from 'react'
import styled from 'styled-components'
import { Box, CircularProgress } from '@mui/material'

export const Loader = () => {
  return (
    <StyledLoader>
      <CircularProgress />
    </StyledLoader>
  )
}
const StyledLoader = styled(Box)`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.37);
`
