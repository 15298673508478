import React, { FC, useState } from 'react'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { IPart, IStock } from '../../types/search'
import { Box, Button, Typography } from '@mui/material'
import styled from 'styled-components'
import { ICart } from '../../types/cart'
import { toast } from 'react-toastify'
import useLocalStorage from 'use-local-storage'
import { cloneDeep } from 'lodash'

interface IAddCart {
  part: IPart
  stock: IStock
}

export const AddCart: FC<IAddCart> = ({ part, stock }) => {
  const [amount, setAmount] = useState(1)
  const [cartLocal, setCartLocal] = useLocalStorage<ICart>('cart', {
    comment: '',
    items: []
  })

  const handleIncrement = () => {
    setAmount((val) => (val < Number(stock.count) ? val + 1 : val))
  }

  const handleDecrement = () => {
    setAmount((val) => (val > 1 ? val - 1 : val))
  }

  const handleAddToCart = () => {
    let clonedCart: ICart = cloneDeep(cartLocal)

    clonedCart?.items?.push({
      partNumber: part.article,
      count: amount,
      price: stock.price,
      delivery: stock.delivery,
      brandId: part.brand.id,
      providerId: stock.provider,
      brandName: part.displayBrand,
      orderBy: stock.orderBy,
      maxCount: Number(stock.count),
      checked: true,
      addedAt: new Date()
    })

    setCartLocal(clonedCart)
    toast.success(
      <>
        {part.article || 'Позицію'} {amount} шт додано в корзину
        <br />
        <hr />
        Пропозиція актуальна 24 години.
      </>,
      {
        autoClose: 4000
      }
    )
  }

  return (
    <>
      <StyledButton onClick={handleDecrement} variant="outlined">
        -
      </StyledButton>
      <Typography minWidth="40px">{amount}</Typography>
      <StyledButton onClick={handleIncrement} variant="outlined">
        +
      </StyledButton>
      <Box ml={2}>
        <AddShoppingCartIcon
          style={{ cursor: 'pointer' }}
          onClick={handleAddToCart}
        />
      </Box>
    </>
  )
}

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 0;
  }
`
