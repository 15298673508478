export enum Currencies {
  UAH = 'UAH',
  USD = 'USD',
  EUR = 'EUR'
}

export interface ICurrency {
  id?: string
  value: number
  currency: Currencies
}
