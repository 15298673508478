import React from 'react'
import { Box } from '@mui/material'
import { UserOrders } from '../../components/UserOrders'

export const UserOrdersPage = () => {
  return (
    <Box display="flex" justifyContent="center" p="50px 20px">
      <UserOrders />
    </Box>
  )
}
