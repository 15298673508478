import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { api } from '../../utils/api'
import { Grid } from '@mui/material'
import { CataloguePartsList } from '../../components/Catalogue/CataloguePartsList'
import Zoom from 'react-medium-image-zoom'
import styled from 'styled-components'
import { Loader } from '../../components'
import { ISingleGroupMap } from '../../types'
import { useResizeDetector } from 'react-resize-detector'

export const PageCatalogueGroupsSingle = () => {
  const { group, catalog, vehicleid, ssd } = useParams()
  const [codeHovered, setCodeHovered] = useState<string | null>(null)
  const [codeSelected, setCodeSelected] = useState<string | null>(null)
  const { width: imageWidth, ref } = useResizeDetector()
  const { data: part, isFetching } = useQuery(
    ['group-single', group, catalog, vehicleid, ssd],
    () =>
      api.getCatalogueGroupSingle({
        group: group || '',
        ssd: ssd || '',
        catalog: catalog || ''
      }),
    { enabled: Boolean(group && catalog && vehicleid && ssd) }
  )

  const details = part?.data?.details

  const getSquare = useCallback(
    ({ x1, x2, y1, y2, code }: ISingleGroupMap) => {
      const currentImageWidth = imageWidth || 0
      const scale =
        (currentImageWidth || 1) / (details?.largeimageurl_width || 0)
      const scaledX1 = Number(x1) * scale
      const scaledX2 = Number(x2) * scale
      const scaledY1 = Number(y1) * scale
      const scaledY2 = Number(y2) * scale
      const width = scaledX2 - scaledX1
      const height = scaledY2 - scaledY1
      const isHighlited = codeHovered === code || codeSelected === code
      return (
        <div
          key={x1 + x2 + y1 + y2 + code}
          style={{
            position: 'absolute',
            left: scaledX1,
            top: scaledY1,
            width,
            height,
            border: isHighlited ? '2px solid red' : 'none',
            cursor: 'pointer'
          }}
          onClick={() => setCodeSelected(code)}
          onMouseEnter={() => setCodeHovered(code)}
          onMouseLeave={() => setCodeHovered(null)}
        />
      )
    },
    [
      details,
      imageWidth,
      codeHovered,
      codeSelected,
      setCodeSelected,
      setCodeHovered
    ]
  )

  const mapZones = useMemo(() => {
    return part?.data?.map?.map((item) => getSquare(item))
  }, [part?.data?.map, getSquare])

  return (
    <>
      {isFetching && <Loader />}
      <Grid
        container
        spacing={2}
        mt={3}
        p={1}
        width="100%"
        maxWidth="1600px"
        m="0 auto">
        <Grid item xs={12} md={8} display="flex" justifyContent="center">
          <Zoom>
            <div style={{ position: 'relative' }}>
              {mapZones}
              <StyledImage
                ref={ref}
                src={details?.largeimageurl}
                alt={details?.name}
              />
            </div>
          </Zoom>
        </Grid>
        <Grid item xs={12} md={4}>
          <CataloguePartsList
            list={part?.data?.parts || []}
            codeHovered={codeHovered}
            setCodeHovered={setCodeHovered}
            codeSelected={codeSelected}
            setCodeSelected={setCodeSelected}
          />
        </Grid>
      </Grid>
    </>
  )
}

const StyledImage = styled.img`
  width: fit-content;
  max-width: 100%;
`
