import { Box } from '@mui/material'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { IImage } from '../../types/search'
import { CustomModal } from '../common'

interface IImages {
  list: IImage[]
}

export const Images: FC<IImages> = ({ list }) => {
  const [openedImage, setOpenedImage] = useState('')

  return (
    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
      {list.map((image) => (
        <StyledImage
          key={image?.imagePath}
          src={image?.thumbnail}
          onClick={() => setOpenedImage(image.fullImagePath)}
          alt="Фото"
        />
      ))}
      <CustomModal
        isOpen={Boolean(openedImage)}
        handleClose={() => setOpenedImage('')}>
        <BigImage
          src={openedImage}
          alt="Фото"
          onClick={() => setOpenedImage('')}
        />
      </CustomModal>
    </Box>
  )
}

const StyledImage = styled.img`
  cursor: pointer;
`

const BigImage = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 70vh;
`
