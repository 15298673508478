import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { ICurrency } from '../../../types'
import { api } from '../../../utils/api'
import { FieldText } from '../../common'
import { toast } from 'react-toastify'

interface ICurrencyManageItem {
  item: ICurrency
}

export const CurrencyManageItem: FC<ICurrencyManageItem> = ({ item }) => {
  const queryClient = useQueryClient()
  const updateCurrency = useMutation(api.updateCurrency, {
    onSuccess: (resp) => {
      toast.success(`${resp?.data?.currency} updated!`)
      queryClient.invalidateQueries('currencies')
    }
  })

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        value: item?.value || 0
      },
      validateOnBlur: true,
      enableReinitialize: true,
      onSubmit: (values) => {
        updateCurrency.mutate({ ...item, value: values.value })
      }
    })

  return (
    <Box display="flex" alignItems="center" gap={4}>
      {item.currency}:
      <StyledForm onSubmit={handleSubmit}>
        <FieldText
          name="value"
          type="number"
          inputProps={{
            step: '0.01'
          }}
          value={values['value']}
          label="Multiplier"
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
        <StyledSubmit type="submit" variant="contained" fullWidth>
          Save
        </StyledSubmit>
      </StyledForm>
    </Box>
  )
}

const StyledForm = styled.form`
  width: 400px;
  display: flex;
`
const StyledSubmit = styled(Button)`
  width: auto !important;
  margin: 16px 0 !important;
  padding: 13px !important;
`
