import {
  IChangePasswordRequest,
  IForgotUpdatePasswordRequest,
  ILogin,
  IPagination,
  IRegister,
  IResetPasswordRequest,
  IUserUpdateRequest
} from '../../types'
import { AxiosResponse } from 'axios'
import { getHeaders } from '../getHeaders'
import { _axios } from './api'

const login = async (data: ILogin): Promise<AxiosResponse> => {
  return _axios.post(`auth/login`, data)
}

const register = async (data: IRegister): Promise<AxiosResponse> => {
  return _axios.post(`users/register`, data)
}

const getMe = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`users/me`, { headers })
}

const getAllUsers = async (
  data: IPagination
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`users`, { headers, params: { ...data } })
}

const deleteUser = async (id: string): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`users/${id}`, { headers })
}

const updateUser = async (
  data: IUserUpdateRequest
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  if (data?.id) {
    return _axios.patch(`users/${data?.id}`, data, {
      headers
    })
  } else {
    throw new Error('Provider Id is undefined')
  }
}

const updateUserById = async (
  data: IUserUpdateRequest
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  if (data?.id) {
    return _axios.patch(`users`, data, {
      headers
    })
  } else {
    throw new Error('Provider Id is undefined')
  }
}

const getPriceGroups = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`users/price-groups`, { headers })
}

const changePassword = async (
  data: IChangePasswordRequest
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }

  return _axios.patch(`users/password`, data, {
    headers
  })
}

const resetPassword = async (
  data: IResetPasswordRequest
): Promise<AxiosResponse> => {
  return _axios.post(`users/reset-password`, data)
}

const updateForgotPassword = async (
  data: IForgotUpdatePasswordRequest
): Promise<AxiosResponse> => {
  return _axios.post(`users/update-forgot-password`, data)
}

const usersApi = {
  login,
  register,
  getMe,
  getAllUsers,
  deleteUser,
  updateUser,
  getPriceGroups,
  changePassword,
  resetPassword,
  updateForgotPassword,
  updateUserById
}

export { usersApi }
