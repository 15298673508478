import React, { useState } from 'react'
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { IOrder } from '../../../types'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import { StyledTableCell } from '../Providers/ProvidersList'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { OrderStatusSelect } from './OrderStatus'

const offset = 25

export const OrdersList = () => {
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data, isLoading } = useQuery(['orders', page], () =>
    api.getAllOrders({ page, offset })
  )
  const deleteOrder = useMutation(api.deleteOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries('orders')
      toast.success('Order Deleted')
    }
  })

  const rows = (data?.data?.data as IOrder[]) || []
  const count = Math.ceil(data?.data?.count / offset) || 0

  if (isLoading) return <Loader />
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell align="center">Sum</StyledTableCell>
              <StyledTableCell align="center">Created At</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Settings</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(rows) &&
              rows.map((row) => (
                <TableRow
                  key={'providerList' + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.user?.alias || row.user.name} (Id: {row.user.id})
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Boolean(row?.order_items?.length) &&
                      row.order_items
                        .map((item) => Number(item.count) * Number(item.price))
                        .reduce((sum, item) => sum + item)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <OrderStatusSelect order={row} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <Tooltip title="View order details" placement="top">
                        <ListAltIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigate(`/admin/orders/${row.id}`)
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Double click to remove" placement="top">
                        <RemoveCircleOutlineIcon
                          color="warning"
                          style={{ cursor: 'pointer', marginLeft: '40px' }}
                          onDoubleClick={() => {
                            if (row.id) deleteOrder.mutate(row.id)
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!Boolean(rows.length) && (
        <Box textAlign="center" my={4}>
          Nothing was found
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="center" my={6}>
        <Pagination
          count={count}
          page={page}
          color="primary"
          onChange={(e, val) => setPage(val)}
        />
      </Box>
    </>
  )
}
