import React, { FC } from 'react'
import {
  Box,
  StandardTextFieldProps,
  TextField,
  Typography
} from '@mui/material'

interface IProps extends StandardTextFieldProps {
  value: string | number
  label: string
  name: string
  handleChange: (value: any) => void
  handleBlur: (value: any) => void
  errors: any
  touched: any
  fullWidth?: boolean
  [key: string]: any
}

export const FieldText: FC<IProps> = ({
  value,
  label,
  name,
  handleChange,
  errors,
  touched,
  handleBlur,
  fullWidth,
  ...rest
}) => {
  return (
    <Box my={2} width={fullWidth ? '100%' : 'auto'}>
      <TextField
        value={value}
        label={label}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth={fullWidth}
        {...rest}
      />
      {/* eslint-disable-next-line react/jsx-no-undef */}
      {Boolean(errors[name] && touched[name]) && (
        <Typography color="red" mt={1} fontSize={14}>
          {errors[name]}
        </Typography>
      )}
    </Box>
  )
}
