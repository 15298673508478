import { getCookie } from './cookies'

export const getHeaders = () => {
  const authCookie = getCookie('auth')
  const authHeader = authCookie
    ? {
        Authorization: `Bearer ${authCookie}`
      }
    : {}
  return {
    ...authHeader
  }
}
