import { AxiosResponse } from 'axios'
import { getHeaders } from '../getHeaders'
import { _axios } from './api'
import { IGetLogsParams, IGetLogsResponse } from '../../types'

const countAllLogs = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`logs/count`, { headers })
}

const getAllLogs = async (
  params: IGetLogsParams
): Promise<AxiosResponse<IGetLogsResponse> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`logs/all`, { headers, params })
}

const deleteAllLogs = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`logs`, { headers })
}

export const logsApi = {
  getAllLogs,
  deleteAllLogs,
  countAllLogs
}
