import React from 'react'
import { useFormik } from 'formik'
import { FieldText } from '../common'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { toast } from 'react-toastify'
import { validationSchema } from './validation'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { deleteCookie } from '../../utils/cookies'

export const UserSettings = () => {
  const [cookies] = useCookies(['auth'])
  const queryClient = useQueryClient()

  const { data: userData } = useQuery('me', api.getMe, {
    enabled: Boolean(cookies?.auth),
    onError: () => deleteCookie('auth', { path: '/' })
  })

  const updateUser = useMutation(api.updateUser, {
    onSuccess: () => {
      toast.success('Успішно!')
      queryClient.invalidateQueries('me')
    }
  })

  const user = userData?.data || {}

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: user?.id || '',
      email: user?.email || '',
      name: user?.name || '',
      city: user?.city || '',
      address: user?.address || '',
      phone: user?.phone || '',
      info_price_percents: user?.info_price_percents || 0
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      updateUser.mutate(values)
    }
  })

  const handleInfoPriceBlur = (e: React.FocusEvent<any, Element>) => {
    if (!e?.target?.value) {
      setFieldValue('info_price_percents', '0')
    } else {
      handleBlur(e)
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Typography
        variant="h4"
        textAlign="center"
        fontWeight={500}
        paddingBottom="20px"
        lineHeight={0.95}>
        Налаштування
      </Typography>
      <FieldText
        name="email"
        value={values['email']}
        label="Email"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      <FieldText
        name="name"
        value={values['name']}
        label="Повне імʼя"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      <FieldText
        name="phone"
        value={values['phone']}
        label="Номер Телефону"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      <FieldText
        name="city"
        value={values['city']}
        label="Місто"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      <FieldText
        name="address"
        value={values['address']}
        label="Адреса (не обовʼязково)"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      {userData?.data?.info_price_available && (
        <FieldText
          name="info_price_percents"
          type="number"
          inputProps={{
            step: 1,
            min: 0,
            max: 100
          }}
          value={values['info_price_percents']}
          label="Персональна націнка при пошуку (%)"
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleInfoPriceBlur}
          fullWidth
        />
      )}
      {userData?.data?.info_price_available && (
        <Box mb={4}>
          <Typography variant="subtitle2">
            Приклад націнки: 100 грн. -&gt;{' '}
            <Tooltip title="100 грн.">
              <span>
                {Math.round(100 * (1 + values['info_price_percents'] / 100))}{' '}
                грн.
              </span>
            </Tooltip>
          </Typography>
        </Box>
      )}

      <Link to="/change-password">Змінити пароль</Link>
      <Box mt={6}>
        <StyledSubmit type="submit" variant="outlined" fullWidth>
          Зберегти зміни
        </StyledSubmit>
      </Box>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  padding: 50px 30px;
  border-radius: 30px;
  flex-direction: column;
  margin: 40px 20px;
  border: 1px solid #c5c5c5;
  background-color: #fff;
  z-index: 1;
`

const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
