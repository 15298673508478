import React, { FC, useEffect, useState } from 'react'
import { Box, Button, Tooltip } from '@mui/material'
import { useParams } from 'react-router'
import { IStorage } from '../../../types'
import { FieldText } from '../../common'
import { cloneDeep } from 'lodash'
import { FieldAutocomplete } from '../../common/FieldAutocomplete'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import styled from 'styled-components'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { toast } from 'react-toastify'

interface IProviderStorages {
  storages: IStorage[]
  fields: string[]
}

export const ProviderStorages: FC<IProviderStorages> = ({
  storages,
  fields
}) => {
  const { id } = useParams()
  const [storagesLocal, setStoragesLocal] = useState<IStorage[]>([])
  const queryClient = useQueryClient()
  const submitStorages = useMutation(api.updateStorages, {
    onSuccess: () => {
      toast.success('Saved')
      queryClient.invalidateQueries('storages')
    }
  })
  const deleteStorage = useMutation(api.deleteStorage, {
    onSuccess: () => {
      queryClient.invalidateQueries('storages')
    }
  })

  useEffect(() => {
    if (storages.length) {
      setStoragesLocal(storages)
    }
  }, [storages])

  const handleChangeDelivery = (newValue: string, index: number) => {
    setStoragesLocal((prev) => {
      const tempArr = cloneDeep(prev)
      tempArr[index].delivery = newValue
      return tempArr
    })
  }

  const handleChangeName = (newValue: string, index: number) => {
    setStoragesLocal((prev) => {
      const tempArr = cloneDeep(prev)
      tempArr[index].name = newValue
      return tempArr
    })
  }

  const handleChangeTime = (newValue: string, index: number) => {
    setStoragesLocal((prev) => {
      const tempArr = cloneDeep(prev)
      tempArr[index].orderBy = newValue
      return tempArr
    })
  }

  const handleAdd = () => {
    setStoragesLocal((prev) => {
      const tempArr = cloneDeep(prev)
      tempArr.push({
        name: '',
        delivery: '1',
        orderBy: '17:00'
      })
      return tempArr
    })
  }

  const handleRemoveStorage = (
    storageId: string | undefined,
    index: number,
    name: string
  ) => {
    if (storageId !== undefined) {
      deleteStorage.mutate(storageId)
    } else {
      setStoragesLocal((prev) => {
        const tempArr = cloneDeep(prev)
        if (tempArr[index].name === name) {
          tempArr.splice(index, 1)
        } else {
          const indexOfItem = tempArr.findIndex((item) => item.name === name)
          tempArr.splice(indexOfItem, 1)
        }
        return tempArr
      })
    }
  }

  const renderStorages = () =>
    storagesLocal.map(({ id: storageId, delivery, name }, index) => {
      return (
        <Box key={`storage-${id}-${index}`} display="flex" mb={-2}>
          <Box pb={2}>
            <FieldText
              value={storagesLocal[index]?.delivery}
              name={`storage-${id}-${index}-delivery`}
              handleChange={(e) => handleChangeDelivery(e.target.value, index)}
              handleBlur={(e) => handleChangeDelivery(e.target.value, index)}
              label="Delivery"
              errors={[]}
              touched={[]}
              fullWidth
            />
          </Box>
          <FieldAutocomplete
            label="Name"
            defaultValue={name}
            value={storagesLocal[index].name}
            name={`storage-${id}-${index}-name`}
            isOptionEqualToValue={(option: any, value: any) => option === value}
            handleChange={(value) => handleChangeName(value, index)}
            handleBlur={(e) => handleChangeName(e.target.value, index)}
            errors={[]}
            touched={[]}
            options={fields}
          />

          <Box pb={2}>
            <FieldText
              label="Order By"
              type="time"
              value={storagesLocal[index]?.orderBy}
              name="time"
              handleChange={(e) => handleChangeTime(e.target.value, index)}
              handleBlur={() => {}}
              errors={[]}
              touched={[]}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 600
              }}
              sx={{ width: 150 }}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            ml={3}>
            <Tooltip title="Double click to remove">
              <RemoveCircleOutlineIcon
                color="warning"
                style={{ cursor: 'pointer' }}
                onDoubleClick={() =>
                  handleRemoveStorage(storageId, index, name)
                }
              />
            </Tooltip>
          </Box>
        </Box>
      )
    })
  return (
    <Box width="100%" maxWidth="800px" m="40px auto">
      {renderStorages()}
      <Box display="flex" justifyContent="flex-end" mt={1.5}>
        <AddCircleOutlineIcon
          color="success"
          style={{ cursor: 'pointer' }}
          onClick={handleAdd}
        />
      </Box>
      <Box mt={1.5}>
        <StyledSubmit
          variant="contained"
          onClick={() => {
            if (id !== undefined) {
              submitStorages.mutate({ data: storagesLocal, providerId: id })
            } else {
              throw new Error('Provider ID is undefined')
            }
          }}>
          Save
        </StyledSubmit>
      </Box>
    </Box>
  )
}

const StyledSubmit = styled(Button)`
  padding: 13px !important;
  width: 100%;
`
