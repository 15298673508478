import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email не валідний.')
    .required('Обовʼязкове поле.')
    .max(100),
  name: Yup.string().required('Обовʼязкове поле.'),
  city: Yup.string().required('Обовʼязкове поле.'),
  address: Yup.string(),
  phone: Yup.string()
    .required('Обовʼязкове поле.')
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      'Не вірний формат.'
    )
})
export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Обовʼязкове поле.'),
  password: Yup.string()
    .required('Обовʼязкове поле.')
    .min(6, 'Мінімальна довжина паролю - 6 символів.')
    .max(100, 'Максимальна довжина паролю - 100 символів.')
})
