import React, { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import styled from 'styled-components'
import { IOrder, OrderStatus } from '../../../types'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'

interface IOrderStatus {
  order: IOrder
}

export const OrderStatusSelect: FC<IOrderStatus> = ({ order }) => {
  const queryClient = useQueryClient()

  const updateOrder = useMutation(api.updateOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries('orders')
      queryClient.invalidateQueries('order_details')
      queryClient.invalidateQueries('unprocessedOrders')
    }
  })

  const setStatus = (status: OrderStatus) => {
    if (order.id) {
      updateOrder.mutate({ id: order.id, status })
    }
  }

  return (
    <>
      <StyledIconButton
        onDoubleClick={() => setStatus(OrderStatus.INPROGRESS)}
        size="large"
        className={order?.status === OrderStatus.INPROGRESS ? 'active' : ''}>
        <Tooltip title="Double Click to change">
          <UpdateIcon color="info" />
        </Tooltip>
      </StyledIconButton>
      <StyledIconButton
        onDoubleClick={() => setStatus(OrderStatus.DONE)}
        size="large"
        className={order?.status === OrderStatus.DONE ? 'active' : ''}>
        <Tooltip title="Double Click to change">
          <CheckCircleOutlineIcon color="success" />
        </Tooltip>
      </StyledIconButton>
      <StyledIconButton
        onDoubleClick={() => setStatus(OrderStatus.REJECTED)}
        size="large"
        className={order?.status === OrderStatus.REJECTED ? 'active' : ''}>
        <Tooltip title="Double Click to change">
          <CancelOutlinedIcon color="warning" />
        </Tooltip>
      </StyledIconButton>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  svg {
    opacity: 0.3;
  }

  &.active {
    svg {
      opacity: 1;
    }
  }
`
