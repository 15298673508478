import { Box } from '@mui/material'
import React from 'react'
import { RegistrationForm } from '../../components'

export const RegistrationPage = () => {
  return (
    <Box px={3} py={6} display="flex" justifyContent="center">
      <RegistrationForm />
    </Box>
  )
}
