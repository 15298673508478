import React from 'react'
import { Box, Typography } from '@mui/material'
import { LogsList } from '../../components'

export const LogsPage = () => {
  return (
    <Box>
      <Box mb={2}>
        <Typography fontSize="25px" fontWeight={500}>
          Logs
        </Typography>
      </Box>

      <LogsList />
    </Box>
  )
}
