import React from 'react'
import { Typography } from '@mui/material'
import { CurrencyManage } from '../../components/admin'

export const DashboardPage = () => {
  return (
    <>
      <Typography fontSize="25px" fontWeight={500}>
        Dashboard
      </Typography>

      <CurrencyManage />
    </>
  )
}
