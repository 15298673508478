import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IBrand } from '../../../types'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import { StyledTableCell } from '../Providers/ProvidersList'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import { AddBrandModal } from './AddBrandModal'
import { colors } from '../../../theme'

export const BrandsList = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [defaultValues, setDefaultValues] = useState<IBrand | undefined>(
    undefined
  )

  useEffect(() => {
    if (Boolean(defaultValues) && !isOpen) setIsOpen(true)
  }, [defaultValues, isOpen])
  const { data: brandsData, isLoading: BrandsLoading } = useQuery(
    'brands',
    api.getBrands
  )

  const queryClient = useQueryClient()

  const deleteBrand = useMutation(api.deleteBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries('brands')
    }
  })

  const brandElements = useMemo(() => {
    const brands: IBrand[] = brandsData?.data || []
    return brands.map((brand) => (
      <TableRow
        key={'brandsList' + brand.id + brand.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align="left">{brand.id}</TableCell>
        <TableCell component="th" scope="row">
          {brand.name}
        </TableCell>
        <TableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Tooltip title="Edit Brand" placement="top">
              <EditIcon
                fill={colors.dark2}
                style={{
                  cursor: 'pointer',
                  width: '30px',
                  height: '30px',
                  marginLeft: '15px'
                }}
                onClick={() => setDefaultValues(brand)}
              />
            </Tooltip>
            <Tooltip title="Double click to remove" placement="top">
              <RemoveCircleOutlineIcon
                color="warning"
                style={{ cursor: 'pointer', marginLeft: '40px' }}
                onDoubleClick={() => {
                  if (brand.id) deleteBrand.mutate(brand.id)
                }}
              />
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsData?.data])

  return (
    <>
      {Boolean(BrandsLoading || deleteBrand.isLoading) && <Loader />}

      {Boolean(isOpen) && (
        <AddBrandModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          defaultValues={defaultValues}
          setDefaultValues={setDefaultValues}
        />
      )}

      <Box mb={4}>
        <Button variant="contained" onClick={() => setIsOpen(true)}>
          Add Brand
        </Button>
      </Box>

      <Box maxWidth="500px">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} size="small" aria-label="brands table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">ID</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="center">Settings</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{brandElements}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
