import { AxiosResponse } from 'axios'
import { IAddBrandRequest, IBrand } from '../../types'
import { getHeaders } from '../getHeaders'
import { _axios } from './api'

const getBrands = async (): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`remains/brands`, { headers })
}

const deleteBrand = async (id: string): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`brands/${id}`, { headers })
}

const addBrand = async (
  data: IAddBrandRequest
): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`brands/add`, data, { headers })
}

const updateBrand = async (
  data: IBrand
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  if (data?.id) {
    return _axios.patch(`brands/${data?.id}`, data, {
      headers
    })
  } else {
    throw new Error('Order Id is undefined')
  }
}

export const brandsApi = {
  getBrands,
  deleteBrand,
  addBrand,
  updateBrand
}
