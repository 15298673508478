import React, { FC } from 'react'
import { useParams } from 'react-router'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import { IRemainsFields } from '../../../types'
import { useFormik } from 'formik'
import { remainsFieldsValidationSchema } from './validations'
import { FieldAutocomplete } from '../../common/FieldAutocomplete'
import { Box, Button } from '@mui/material'
import styled from 'styled-components'

interface IRemainsFieldsProps {
  fields: string[]
}

export const RemainsFields: FC<IRemainsFieldsProps> = ({ fields }) => {
  const { id } = useParams()
  const queryClient = useQueryClient()

  const { data: fileFields, isLoading } = useQuery(['fileFields', id], () =>
    api.getFileFields(id || '')
  )

  const updateFileFields = useMutation(api.updateFileFields, {
    onSuccess: () => {
      queryClient.invalidateQueries('fileFields')
    }
  })

  const initialValues: IRemainsFields | null =
    (fileFields?.data as IRemainsFields) || null

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initialValues || {
        brand: '',
        name: '',
        partNumber: '',
        price: ''
      },
      validationSchema: remainsFieldsValidationSchema,
      validateOnBlur: true,
      enableReinitialize: true,
      onSubmit: (values) => {
        updateFileFields.mutate(values)
      }
    })

  const handleSelect = (name: string, newValue: string) => {
    setFieldValue(name, newValue)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <FieldAutocomplete
          value={values['brand']}
          defaultValue={initialValues?.brand || ''}
          label={'Brand'}
          name={'brand'}
          isOptionEqualToValue={(option: any, value: any) => option === value}
          handleChange={(newValue) => handleSelect('brand', newValue)}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          options={fields}
        />
        <FieldAutocomplete
          value={values['name']}
          defaultValue={initialValues?.name || ''}
          label={'Name'}
          name={'name'}
          isOptionEqualToValue={(option: any, value: any) => option === value}
          handleChange={(newValue) => handleSelect('name', newValue)}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          options={fields}
        />
        <FieldAutocomplete
          value={values['price']}
          defaultValue={initialValues?.price || ''}
          label={'Price'}
          name={'price'}
          isOptionEqualToValue={(option: any, value: any) => option === value}
          handleChange={(newValue) => handleSelect('price', newValue)}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          options={fields}
        />
        <FieldAutocomplete
          value={values['partNumber']}
          defaultValue={initialValues?.partNumber || ''}
          label={'Part Number'}
          name={'partNumber'}
          isOptionEqualToValue={(option: any, value: any) => option === value}
          handleChange={(newValue) => handleSelect('partNumber', newValue)}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          options={fields}
        />

        <Box mt={6}>
          <StyledSubmit type="submit" variant="contained" fullWidth>
            Save
          </StyledSubmit>
        </Box>
      </StyledForm>
    </>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  margin: 0 auto;
  overflow: auto;
`
const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
