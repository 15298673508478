import React from 'react'
import { Box } from '@mui/material'
import { ChangePassword } from '../../components/UserSettings/ChangePassword'

export const ChangePasswordPage = () => {
  return (
    <Box display="flex" justifyContent="center">
      <ChangePassword />
    </Box>
  )
}
