import React, { FC } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'

interface IFieldAutocomplete {
  label: string
  name: string
  handleChange: (value: any) => void
  handleBlur: (value: any) => void
  errors: any
  touched: any
  options: any[]
  defaultValue?: string
  [key: string]: any
}

export const FieldAutocomplete: FC<IFieldAutocomplete> = ({
  label,
  name,
  handleChange,
  handleBlur,
  errors,
  touched,
  defaultValue,
  options,
  ...rest
}) => {
  return (
    <Box my={2} width="100%">
      <Autocomplete
        defaultValue={defaultValue}
        onChange={(e, val) => handleChange(val)}
        onBlur={handleBlur}
        options={options}
        {...rest}
        renderInput={(props) => <TextField label={label} {...props} />}
      />
      {/* eslint-disable-next-line react/jsx-no-undef */}
      {Boolean(errors[name] && touched[name]) && (
        <Typography color="red" mt={1} fontSize={14}>
          {errors[name]}
        </Typography>
      )}
    </Box>
  )
}
