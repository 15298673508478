import React, { FC } from 'react'
import { Box, Modal } from '@mui/material'
import styled from 'styled-components'

interface ICustomModal {
  isOpen: boolean
  handleClose: () => void
  children: React.ReactNode
}

export const CustomModal: FC<ICustomModal> = ({
  isOpen,
  handleClose,
  children
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <StyledContentWrapper>{children}</StyledContentWrapper>
    </Modal>
  )
}

const StyledContentWrapper = styled(Box)`
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
`
