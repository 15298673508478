import { AxiosResponse } from 'axios'
import { _axios } from './api'
import { getHeaders } from '../getHeaders'
import {
  ICatalogueGroupFoundItem,
  ICatalogueGroupItem,
  ICatalogueGroupUnit,
  ICatalogueSearchVinResponse,
  IGetCatalogueGroupResultsRequest,
  IGetCatalogueGroupSingleRequest,
  IGetCatalogueGroupsRequest,
  ISingleGroupResponse
} from '../../types'

const catalogueSearchByVIN = async (
  vin: string
): Promise<AxiosResponse<ICatalogueSearchVinResponse[]> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`catalogue/find-by-vin/${vin}`, { headers })
}

const getCatalogueGroups = async ({
  catalog,
  vehicleid,
  ssd
}: IGetCatalogueGroupsRequest): Promise<
  AxiosResponse<ICatalogueGroupItem[]> | undefined
> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`catalogue/groups/${catalog}/${vehicleid}/${ssd}`, {
    headers
  })
}
const getCatalogueCategories = async ({
  catalog,
  vehicleid,
  ssd
}: IGetCatalogueGroupsRequest): Promise<
  AxiosResponse<ICatalogueGroupItem[]> | undefined
> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`catalogue/categories/${catalog}/${vehicleid}/${ssd}`, {
    headers
  })
}
const getCatalogueGroupResults = async ({
  group,
  catalog,
  vehicleid,
  ssd
}: IGetCatalogueGroupResultsRequest): Promise<
  AxiosResponse<ICatalogueGroupFoundItem[]> | undefined
> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(
    `catalogue/group-results/${group}/${catalog}/${vehicleid}/${ssd}`,
    {
      headers
    }
  )
}

const getUnitsByCategory = async ({
  group,
  catalog,
  vehicleid,
  ssd
}: IGetCatalogueGroupResultsRequest): Promise<
  AxiosResponse<ICatalogueGroupUnit[]> | undefined
> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(
    `catalogue/units-by-category/${catalog}/${group}/${vehicleid}/${ssd}`,
    {
      headers
    }
  )
}

const getCatalogueGroupSingle = async ({
  group,
  catalog,
  ssd
}: IGetCatalogueGroupSingleRequest): Promise<
  AxiosResponse<ISingleGroupResponse> | undefined
> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`catalogue/group-single/${catalog}/${group}/${ssd}`, {
    headers
  })
}

export const catalogueApi = {
  catalogueSearchByVIN,
  getCatalogueGroups,
  getCatalogueGroupResults,
  getCatalogueGroupSingle,
  getCatalogueCategories,
  getUnitsByCategory
}
