import React, { FC } from 'react'
import { ICatalogueGroupUnit } from '../../../types'
import { Grid } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { colors } from '../../../theme'
import styled from 'styled-components'
import { CataloguePartsList } from '../CataloguePartsList'
import Zoom from 'react-medium-image-zoom'

interface ICatalogueGroupsDetails {
  list: ICatalogueGroupUnit[]
}

export const CatalogueGroupsDetails: FC<ICatalogueGroupsDetails> = ({
  list
}) => {
  const { catalog, vehicleid } = useParams()
  return (
    <>
      {list.map((item) => (
        <Grid container key={'unit-' + item?.unitid} mb={2}>
          <Grid
            item
            display="flex"
            flexDirection="column"
            gap={1}
            xs={12}
            md={5}>
            <Zoom zoomImg={{ src: item?.largeimageurl }}>
              <StyledImage src={item?.imageurl} alt={item?.name} />
            </Zoom>
            <StyledLink
              to={`/catalogue-group/${catalog}/${item?.unitid}/${vehicleid}/${item?.ssd}`}>
              {item?.code} - {item?.name}
            </StyledLink>
          </Grid>
          <Grid item xs={12} md={7}>
            <CataloguePartsList list={item?.details || []} />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

const StyledLink = styled(Link)`
  color: ${colors.dark2};
  text-decoration: underline;
`

const StyledImage = styled.img`
  width: fit-content;
  max-width: 170px;
  max-height: 170px;
`
