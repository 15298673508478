import React, { FC } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { StyledTableCell } from './ProvidersList'
import { IUploadSettings, IUploadSettingsRequest } from '../../../types'
import EditIcon from '@mui/icons-material/Edit'
import { colors } from '../../../theme'
import DeleteIcon from '@mui/icons-material/Delete'

interface IUploadSettingsProps {
  providerId: string
  setEdit: (p: IUploadSettingsRequest) => void
}

export const UploadSettings: FC<IUploadSettingsProps> = ({
  providerId,
  setEdit
}) => {
  const { data: settingsData, isLoading: settingsLoading } = useQuery(
    ['uploadSettings', providerId],
    () => api.getUploadSettings(providerId)
  )
  const queryClient = useQueryClient()
  const deleteSetting = useMutation(api.deleteUploadSetting, {
    onSuccess: () => {
      queryClient.invalidateQueries('uploadSettings')
    }
  })
  const settingRows = settingsData?.data || []

  const onEditClick = (row: IUploadSettings) => {
    setEdit({ ...row, provider: providerId, brand: row?.brand?.id || '' })
  }

  if (settingsLoading) return <Loader />

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 1000 }}
          stickyHeader
          size="small"
          aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell>Brand</StyledTableCell>
              <StyledTableCell align="center">Brand File</StyledTableCell>
              <StyledTableCell align="center">Prefix Add</StyledTableCell>
              <StyledTableCell align="center">Prefix Remove</StyledTableCell>
              <StyledTableCell align="center">Suffix Add</StyledTableCell>
              <StyledTableCell align="center">Suffix Remove</StyledTableCell>
              <StyledTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(settingRows) &&
              settingRows.map((row: IUploadSettings) => (
                <TableRow
                  key={'providerList' + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {row?.brand?.name || ''}
                  </TableCell>
                  <TableCell align="center">{row.brand_file}</TableCell>
                  <TableCell align="center">{row.prefix_add}</TableCell>
                  <TableCell align="center">{row.prefix_remove}</TableCell>
                  <TableCell align="center">{row.suffix_add}</TableCell>
                  <TableCell align="center">{row.suffix_remove}</TableCell>
                  <TableCell align="center">
                    <Box display="flex" alignItems="center">
                      <EditIcon
                        style={{
                          cursor: 'pointer',
                          width: '30px',
                          height: '30px'
                        }}
                        fill={colors.dark2}
                        onClick={() => onEditClick(row)}
                      />

                      <Tooltip title="Double click to delete">
                        <DeleteIcon
                          style={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            marginLeft: '15px'
                          }}
                          fill={colors.dark2}
                          onDoubleClick={() => deleteSetting.mutate(row.id)}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!Boolean(settingRows.length) && (
        <Box textAlign="center" my={4}>
          Nothing was found
        </Box>
      )}
    </>
  )
}
