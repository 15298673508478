import React, { useMemo } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { api } from '../../../utils/api'
import { useQuery } from 'react-query'
import { Loader } from '../../common'
import { IOrder } from '../../../types'
import { StyledTableCell } from '../Providers/ProvidersList'
import { OrderStatusSelect } from './OrderStatus'

export const OrderDetails = () => {
  const { id } = useParams()
  const { data, isLoading } = useQuery([`order_details`, id], () =>
    api.getOrderById(id || '')
  )

  const orderDetails = useMemo(() => (data?.data as IOrder) || {}, [data?.data])

  const userName = useMemo(() => {
    return orderDetails?.user?.alias
      ? `${orderDetails?.user?.alias} (${orderDetails?.user?.name})(${orderDetails?.user?.id})`
      : `${orderDetails?.user?.name}(${orderDetails?.user?.id})`
  }, [orderDetails])

  return (
    <>
      {isLoading && <Loader />}
      <Box mb={2}>
        <Typography fontSize="25px">
          Номер Замовлення: <b>#{orderDetails?.id}</b> <br />
          Імʼя: <b>{userName} </b> <br />
          Телефон: <b>{orderDetails?.user?.phone} </b> <br />
          Адреса:{' '}
          <b>
            {orderDetails?.user?.address} {orderDetails?.user?.city}
          </b>{' '}
          <br />
          Коментар: <b>{orderDetails?.comment || ' - '}</b>
        </Typography>
        <Typography fontSize="25px">
          Сума:{' '}
          {Boolean(orderDetails?.order_items?.length) &&
            orderDetails.order_items
              .map((item) => Number(item.count) * Number(item.price))
              .reduce((sum, item) => sum + item)}
        </Typography>
        <Typography fontSize="25px">
          Підсумок:{' '}
          <b>
            Сайт, #{orderDetails?.id}, {orderDetails?.user?.address}{' '}
            {orderDetails?.user?.city}, {userName}, {orderDetails?.user?.phone}
            {orderDetails?.comment ? `, ${orderDetails?.comment}` : ''}
          </b>
        </Typography>
        <OrderStatusSelect order={orderDetails} />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Index</StyledTableCell>
              <StyledTableCell>Артикул</StyledTableCell>
              <StyledTableCell align="center">Бренд</StyledTableCell>
              <StyledTableCell align="center">Постачальник</StyledTableCell>
              <StyledTableCell align="center">Кількість</StyledTableCell>
              <StyledTableCell align="center">Ціна за 1 шт.</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(orderDetails?.order_items) &&
              orderDetails?.order_items?.map((row, index) => (
                <TableRow
                  key={'providerList' + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell align="left">{index + 1}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.partNumber}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.brand?.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography fontWeight="bold">
                      {row?.provider?.name}
                    </Typography>
                    Доставка:{' '}
                    <Typography fontWeight="bold" display="inline">
                      {row?.delivery}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography fontWeight="bold">{row?.count}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">{row?.price}</StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!Boolean(orderDetails?.order_items?.length) && (
        <Box textAlign="center" my={4}>
          Nothing was found
        </Box>
      )}
    </>
  )
}
