import React, { FC, useState } from 'react'
import { IPart } from '../../../../types'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { AddCross } from './AddCross'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '../../../../theme'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../../../utils/api'

interface ICrossList {
  crosses: IPart[]
  defaultId?: string
}

export const CrossList: FC<ICrossList> = ({ crosses, defaultId }) => {
  const [addCrossOpen, setAddCrossOpen] = useState(false)
  const queryClient = useQueryClient()
  const { mutate: removeCross } = useMutation(api.removePartsCross, {
    onSuccess: () => {
      queryClient.invalidateQueries(['parts'])
      queryClient.invalidateQueries(['part_details'])
    },
    retry: false
  })

  return (
    <>
      <Typography mt={4} mb={2}>
        Кроси (до замінників оригінал, до оригінала - замінники):
      </Typography>

      {!crosses.length && <Typography>Немає аналогів</Typography>}
      {crosses.map((item) => (
        <Box
          key={`cross-list - ${item.id}`}
          display="flex"
          alignItems="center"
          mb={1}
          gap={2}>
          <Tooltip title="Подвійний клік для видалення" placement="left">
            <IconButton
              onDoubleClick={() => {
                if (defaultId && item.id)
                  removeCross({ partId_1: defaultId, partId_2: item.id })
              }}>
              <RemoveCircleRoundedIcon color="error" />
            </IconButton>
          </Tooltip>

          <StyledSearchLink
            to={`/?brand=${item?.brand?.name}&partNumber=${item?.partNumber}`}>
            <SearchIcon color="info" />
          </StyledSearchLink>

          <StyledLink to={`/admin/parts/${item.id}`}>
            {item?.partNumberDirty} - {item?.brand?.name}
          </StyledLink>
        </Box>
      ))}

      {addCrossOpen ? (
        <>
          <AddCross defaultId={defaultId} setAddCrossOpen={setAddCrossOpen} />
        </>
      ) : (
        <IconButton onClick={() => setAddCrossOpen(true)}>
          <AddCircleRoundedIcon color="success" />
        </IconButton>
      )}
    </>
  )
}

const StyledLink = styled(Link)`
  color: ${colors.dark2};
  text-decoration: underline;
`

const StyledSearchLink = styled(Link)`
  line-height: 0;
`
