import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { SearchField } from '../../components/Search'

import { IPart } from '../../types/search'
import { PartsList } from '../../components/Search/PartsList'

export const MainPage = () => {
  const [availableBrands, setAvailableBrands] = useState([])
  const [analogs, setAnalogs] = useState<IPart[]>([])
  const [activeBrand, setActiveBrand] = useState('')

  return (
    <Box maxWidth="900px" margin="0 auto" p={3}>
      <Box
        display="flex"
        justifyContent="center"
        mt={6}
        mb={6}
        flexDirection="column">
        <Typography
          fontSize={{ xs: '24px', sm: '34px' }}
          px={1}
          fontWeight="500"
          textAlign="center"
          mb={4}>
          Пошук по артикулу
        </Typography>
        <SearchField
          availableBrands={availableBrands}
          setAvailableBrands={setAvailableBrands}
          setAnalogs={setAnalogs}
          analogs={analogs}
          activeBrand={activeBrand}
          setActiveBrand={setActiveBrand}
        />
      </Box>
      <Box display="flex" alignContent="center" flexDirection="column">
        <PartsList list={analogs} activeBrand={activeBrand} />
      </Box>
    </Box>
  )
}
