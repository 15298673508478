import React, { useCallback, useRef, useState } from 'react'
import { read, utils } from 'xlsx'
import { Loader } from '../../common'
import { RemainsFields } from './RemainsFields'
import { isEmpty } from 'lodash'
import { Box, Button, Tooltip } from '@mui/material'
import styled from 'styled-components'
import { useMutation, useQuery } from 'react-query'
import { api } from '../../../utils/api'
import { useParams } from 'react-router'
import { IRemainsFields } from '../../../types'
import { ProviderStorages } from './ProviderStorages'
import { toast } from 'react-toastify'

export const UploadRemains = () => {
  const [remains, setRemains] = useState<any[]>([])
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  const inputFileRef = useRef(null)
  const { id } = useParams()
  const { data: providerData, isLoading: providerLoading } = useQuery(
    ['provider', id],
    () => api.getProviderById(id || ''),
    { enabled: Boolean(id) }
  )

  const { data: fileFields, isLoading: fileFieldsLoading } = useQuery(
    ['fileFields', id],
    () => api.getFileFields(id || '')
  )

  const { data: storages, isLoading: storagesLoading } = useQuery(
    ['storages', id],
    () => api.getStorages(id || '')
  )

  const uploadRemains = useMutation(api.uploadRemains, {
    retry: false,
    onSuccess: (resp) => {
      if (resp?.data) {
        console.log(resp?.data)
        toast.success(`Uploaded: ${providerData?.data?.name}`, {
          autoClose: false
        })
        document.title = '✅✅✅✅✅✅✅'
      } else {
        console.log(resp?.data)
        toast.error(`Not uploaded: ${providerData?.data?.name}`, {
          autoClose: false
        })
        document.title = '⛔️⛔️⛔️⛔️⛔️⛔️⛔️'
      }
    },
    onError: (error) => {
      document.title = '⛔️⛔️⛔️⛔️⛔️⛔️⛔️'
      toast.error(`Not uploaded: ${providerData?.data?.name}, ${error}`, {
        autoClose: false
      })
    }
  })
  const clearRemains = useMutation(api.deleteRemainsByProviderId, {
    onSuccess: () => {
      toast.success(`Cleared`)
    }
  })

  const fieldNames = fileFields?.data as IRemainsFields

  const readUploadFile = useCallback((e: any) => {
    e.preventDefault()
    if (e.target.files) {
      setFileName(e.target.files[0]?.name)
      setIsFileLoading(true)
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e?.target?.result
        const workbook = read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet, { defval: '' })
        setRemains(json)
        setIsFileLoading(false)
        document.title = '📒📒📒📒📒📒📒📒'
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }, [])

  const submitUpload = (remains: any[]) => {
    if (id !== undefined) {
      document.title = '⏩️⏩️⏩️⏩️⏩️⏩️⏩️⏩️'
      uploadRemains.mutate({
        remains: remains.map((item: any) => {
          const newValue = {
            brand: item[fieldNames.brand],
            price:
              typeof item[fieldNames.price] === 'number'
                ? item[fieldNames.price].toFixed(2)
                : item[fieldNames.price],
            partNumber: item[fieldNames.partNumber],
            name: item[fieldNames.name]
          }
          delete item[fieldNames.brand]
          delete item[fieldNames.price]
          delete item[fieldNames.partNumber]
          delete item[fieldNames.name]

          return { ...newValue, ...item }
        }),
        options: {
          provider: id,
          storages: storages?.data || []
        }
      })
    }
  }

  const deleteRemainsByProviderId = useCallback(() => {
    if (id) clearRemains.mutate(id)
  }, [clearRemains, id])

  return (
    <>
      {Boolean(
        isFileLoading ||
          fileFieldsLoading ||
          storagesLoading ||
          uploadRemains.isLoading ||
          providerLoading
      ) && <Loader />}
      <Button
        variant="outlined"
        // @ts-ignore
        onClick={() => inputFileRef?.current?.click()}>
        {fileName ? fileName : 'Choose File'}
      </Button>
      {Boolean(remains.length) && (
        <Box ml={2} display="inline-block">
          <Button variant="contained" onClick={() => submitUpload(remains)}>
            Upload
          </Button>
        </Box>
      )}

      <Box ml={2} display="inline-block">
        <Tooltip title="Double click to clear">
          <Button
            variant="contained"
            color="error"
            onDoubleClick={() => deleteRemainsByProviderId()}>
            Clear This Remains
          </Button>
        </Tooltip>
      </Box>

      <StyledFileInput
        type="file"
        name="upload"
        onChange={readUploadFile}
        ref={inputFileRef}
      />

      <Box gap="40px" my={4}>
        {Boolean(storages?.data && remains.length) && (
          <RemainsFields
            fields={!isEmpty(remains) ? Object.keys(remains[0]) : []}
          />
        )}
        <ProviderStorages
          storages={storages?.data || []}
          fields={!isEmpty(remains) ? Object.keys(remains[0]) : []}
        />
      </Box>
    </>
  )
}

const StyledFileInput = styled.input`
  position: fixed;
  left: -50px;
  top: -50px;
  z-index: 0;
  width: 1px;
  height: 1px;
  opacity: 0.1;
`
