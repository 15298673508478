import React, { FC, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { Loader } from '../../common'
import { IUser } from '../../../types'
import { colors } from '../../../theme'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { StyledTableCell } from '../Providers/ProvidersList'
import { toast } from 'react-toastify'

interface IUsersList {
  setDefaultValues: (p: IUser) => void
}

const offset = 25

export const UsersList: FC<IUsersList> = ({ setDefaultValues }) => {
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery(['users', page], () =>
    api.getAllUsers({ page, offset })
  )
  const deleteUser = useMutation(api.deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      toast.success('User Deleted')
    }
  })

  const rows = (data?.data?.data as IUser[]) || []
  const count = Math.ceil(data?.data?.count / offset) || 0

  if (isLoading) return <Loader />
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Irresponsible</StyledTableCell>
              <StyledTableCell align="center">Phone</StyledTableCell>
              <StyledTableCell align="center">Price Group</StyledTableCell>
              <StyledTableCell align="center">Settings</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(rows) &&
              rows.map((row) => (
                <TableRow
                  key={'providerList' + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row?.alias
                      ? `${row?.alias} (${row?.name})`
                      : `${row?.name}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.irresponsible ? (
                      <Typography color="red" fontWeight={700}>
                        WARNING
                      </Typography>
                    ) : (
                      <Typography color="green" fontWeight={700}>
                        OK
                      </Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.phone}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box>
                      <Tooltip
                        title={row?.price_group?.multiplier?.toString()}
                        placement="top">
                        <Box>{row?.price_group?.name}</Box>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <Tooltip title="Edit User" placement="top">
                        <EditIcon
                          fill={colors.dark2}
                          style={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            marginLeft: '15px'
                          }}
                          onClick={() => setDefaultValues(row)}
                        />
                      </Tooltip>

                      <Tooltip title="Double click to remove" placement="top">
                        <RemoveCircleOutlineIcon
                          color="warning"
                          style={{ cursor: 'pointer', marginLeft: '40px' }}
                          onDoubleClick={() => {
                            if (row.id) deleteUser.mutate(row.id)
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!Boolean(rows.length) && (
        <Box textAlign="center" my={4}>
          Nothing was found
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="center" my={6}>
        <Pagination
          count={count}
          page={page}
          color="primary"
          onChange={(e, val) => setPage(val)}
        />
      </Box>
    </>
  )
}
