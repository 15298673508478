import React, { FC, useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Tooltip,
  IconButton
} from '@mui/material'
import { IProvider, IProviders } from '../../../types'
import styled from 'styled-components'
import SettingsIcon from '@mui/icons-material/Settings'
import { colors } from '../../../theme'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import { Link } from 'react-router-dom'

interface IProvidersList {
  setDefaultValues: (p: IProvider) => void
}

export const ProvidersList: FC<IProvidersList> = ({ setDefaultValues }) => {
  const { isLoading, data } = useQuery('providers', api.getAllProviders)
  const rows: IProviders[] = useMemo(() => data?.data || [], [data])
  const queryClient = useQueryClient()
  const deleteProvider = useMutation(api.deleteProvider, {
    onSuccess: () => {
      queryClient.invalidateQueries('providers')
    }
  })
  const onUploadAll = useCallback(() => {
    rows.forEach((row) => {
      window
        ?.open(`/admin/providers/${row.id}/upload-remains`, '_blank')
        ?.focus()
    })
  }, [rows])

  if (isLoading) return <Loader />

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="doubleClick to upload all" placement="top">
          <IconButton onDoubleClick={onUploadAll}>
            <UploadFileIcon
              fill={colors.dark2}
              style={{
                cursor: 'pointer',
                width: '30px',
                height: '30px'
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell>Імʼя</StyledTableCell>
              <StyledTableCell align="center">
                Останнє оновлення
              </StyledTableCell>
              <StyledTableCell align="center">Націнка</StyledTableCell>
              <StyledTableCell align="center">Валюта</StyledTableCell>
              <StyledTableCell align="center">Налаштування</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(rows) &&
              rows.map((row: IProviders) => (
                <TableRow
                  key={'providerList' + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.name} {row.unreturnable && ` (Без повернення)`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Boolean(row?.lastRemainsUpdate) &&
                      moment(row.lastRemainsUpdate).format('DD-MM-YYYY, HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.multiplier}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <Tooltip title={row.name} placement="top">
                        <StyledLink
                          to={`/admin/providers/${row.id}/upload-remains`}>
                          <UploadFileIcon
                            fill={colors.dark2}
                            style={{
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px'
                            }}
                          />
                        </StyledLink>
                      </Tooltip>
                      <Tooltip title="Upload Settings" placement="top">
                        <StyledLink
                          to={`/admin/providers/${row.id}/upload-settings`}>
                          <SettingsIcon
                            fill={colors.dark2}
                            style={{
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              marginLeft: '15px'
                            }}
                          />
                        </StyledLink>
                      </Tooltip>

                      <Tooltip title="Edit Provider" placement="top">
                        <EditIcon
                          fill={colors.dark2}
                          style={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            marginLeft: '15px'
                          }}
                          onClick={() => setDefaultValues(row)}
                        />
                      </Tooltip>

                      <Tooltip title="Double click to remove" placement="top">
                        <RemoveCircleOutlineIcon
                          color="warning"
                          style={{ cursor: 'pointer', marginLeft: '40px' }}
                          onDoubleClick={() => deleteProvider.mutate(row.id)}
                        />
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!Boolean(rows.length) && (
        <Box textAlign="center" my={4}>
          Nothing was found
        </Box>
      )}
    </>
  )
}

export const StyledTableCell = styled(TableCell)`
  padding: 18px 16px !important;
`

const StyledLink = styled(Link)`
  color: ${colors.dark2};
`
