import React from 'react'
import { Box, Typography } from '@mui/material'
import { SearchVIN } from '../../components/SearchVIN'

export const PageSearchVIN = () => {
  return (
    <Box textAlign="center" mt={6}>
      <Typography
        fontSize={{ xs: '24px', sm: '34px' }}
        px={1}
        fontWeight="500"
        textAlign="center"
        mt={9}
        mb={2}>
        Каталог по номеру шасі (кузова, рами)
      </Typography>
      <SearchVIN />
    </Box>
  )
}
