import React, { FC } from 'react'
import { CustomModal, FieldSelect, FieldText, Loader } from '../../common'
import { Box, Button } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { useFormik } from 'formik'
import { IUser, IUserPriceGroup, Roles } from '../../../types'
import styled from 'styled-components'
import { FieldCheckbox } from '../../common/FieldCheckbox'

interface IAddProviderModal {
  defaultValues: IUser
  isOpen: boolean
  setIsOpen: (p: boolean) => void
  setDefaultValues: (p: IUser | undefined) => void
}

export const EditUserModal: FC<IAddProviderModal> = ({
  defaultValues,
  isOpen,
  setIsOpen,
  setDefaultValues
}) => {
  const { data: priceGroups, isLoading } = useQuery(
    'price-groups',
    api.getPriceGroups
  )
  const queryClient = useQueryClient()
  const updateUser = useMutation(api.updateUserById, {
    onSuccess: () => {
      handleClose()
      queryClient.invalidateQueries('users')
    }
  })

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values?.id) {
        updateUser.mutate({ ...values })
      }
    }
  })

  const handleClose = () => {
    resetForm()
    setIsOpen(false)
    setDefaultValues(undefined)
  }

  const onPriceGroupChange = (e: any) => {
    const groupId = e.target.value
    const groups = priceGroups?.data || []

    setFieldValue(
      'price_group',
      groups.find((item: IUserPriceGroup) => item.id === groupId)
    )
  }

  if (isLoading) return <Loader />

  return (
    <>
      <CustomModal isOpen={isOpen} handleClose={handleClose}>
        <StyledForm onSubmit={handleSubmit}>
          <FieldText
            name="alias"
            value={values['alias'] || ''}
            label="Псевдонім (прихований)"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />

          <FieldText
            name="name"
            value={values['name']}
            label="Імʼя"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldText
            name="email"
            value={values['email']}
            label="Email"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldText
            name="phone"
            value={values['phone']}
            label="Номер"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            fullWidth
          />
          <FieldSelect
            name="roles"
            value={values['roles']}
            label="Роль"
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            options={Object.values(Roles).map((key) => ({
              title: key,
              value: key
            }))}
            fullWidth
          />
          <Box my={2}>
            <FieldSelect
              name="price_group"
              value={values['price_group']?.id || ''}
              label="Цінова група"
              handleChange={onPriceGroupChange}
              errors={errors}
              touched={touched}
              handleBlur={onPriceGroupChange}
              options={priceGroups?.data?.map((item: IUserPriceGroup) => ({
                title: `${item.name} (${item.multiplier})`,
                value: item.id
              }))}
              fullWidth
            />
          </Box>

          <FieldCheckbox
            name="info_price_available"
            value={values['info_price_available'] || false}
            label="Може редагувати персональну націнку"
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
          />

          <Box mt={6}>
            <StyledSubmit type="submit" variant="contained" fullWidth>
              Submit
            </StyledSubmit>
          </Box>
        </StyledForm>
      </CustomModal>
    </>
  )
}

const StyledForm = styled.form`
  width: 400px;
  flex-direction: column;
`
const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
