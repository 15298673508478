import { IProvider } from './providers'
import { IBrand } from './remains'
import { IUser } from './users'

export enum OrderStatus {
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  INPROGRESS = 'INPROGRESS'
}

export interface IOrderItem {
  id?: string
  brand: IBrand
  count: string
  createdAt: string
  delivery: string
  partNumber: string
  price: string
  provider: IProvider
}

export interface IOrder {
  id?: string
  comment: string
  order_items: IOrderItemsResponse[]
  status: OrderStatus
  createdAt: string
  user: IUser
}

export interface IOrderItemsResponse {
  brand: IBrand
  count: string
  createdAt: string
  delivery: string
  id: string
  partNumber: string
  price: string
  provider?: IProvider
}
export interface IOrderResponse {
  comment: string
  createdAt: string
  id: string
  status: OrderStatus
  order_items: IOrderItemsResponse[]
}

export interface IOrderUpdateRequest {
  id: string
  comment?: string
  createdAt?: string
  status: OrderStatus
  order_items?: IOrderItemsResponse[]
}
