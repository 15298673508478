import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email не валідний.')
    .required('Обовʼязкове поле.')
    .max(100),
  password: Yup.string()
    .required('Обовʼязкове поле.')
    .min(6, 'Мінімальна довжина паролю - 6 символів.')
    .max(100, 'Максимальна довжина паролю - 100 символів.'),
  passwordRepeat: Yup.string()
    .required('Обовʼязкове поле.')
    .oneOf([Yup.ref('password'), null], 'Паролі не однакові.'),
  name: Yup.string().required('Обовʼязкове поле.'),
  city: Yup.string().required('Обовʼязкове поле.'),
  address: Yup.string(),
  phone: Yup.string()
    .required('Обовʼязкове поле.')
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      'Не вірний формат.'
    ),
  agreements: Yup.boolean().oneOf([true], 'Обовʼязкове поле.')
})
