import axios, { AxiosResponse } from 'axios'
import { getHeaders } from '../getHeaders'
import {
  IRemainsFields,
  IUpdateStoragesRequest,
  IUploadRemainsRequest
} from '../../types'
import { IUploadSettingsRequest } from '../../types'
import { usersApi } from './users'
import { providers } from './providers'
import { orders } from './orders'
import { IGetAnalogsParams } from '../../types/search'
import { currenciesApi } from './currencies'
import { brandsApi } from './brands'
import { logsApi } from './logs'
import { partsApi } from './parts'
import { catalogueApi } from './catalogue'

export const _axios = axios.create()
_axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || ''

const uploadRemains = async (
  data: IUploadRemainsRequest
): Promise<AxiosResponse<void> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`/remains/upload`, data, { headers })
}

const deleteRemainsByProviderId = async (
  id: string
): Promise<AxiosResponse<IRemainsFields> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`remains/delete-remains-by-provider`, {
    headers,
    params: { id }
  })
}

const getUploadSettings = async (
  providerId: string
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`remains/upload-settings/${providerId}`, { headers })
}

const getFileFields = async (
  providerId: string
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`remains/get-file-fields`, {
    headers,
    params: { providerId }
  })
}

const getStorages = async (
  providerId: string
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.get(`remains/get-storages`, {
    headers,
    params: { providerId }
  })
}

const updateFileFields = async (
  data: IRemainsFields
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.patch(`remains/update-file-fields`, data, {
    headers
  })
}

const updateStorages = async (
  data: IUpdateStoragesRequest
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.patch(`remains/update-storages`, data, {
    headers
  })
}

const deleteStorage = async (
  id: string
): Promise<AxiosResponse<IRemainsFields> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`remains/delete-storage`, {
    headers,
    params: { id }
  })
}

const addUploadSetting = async (
  data: IUploadSettingsRequest
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.post(`remains/add-upload-setting`, data, { headers })
}

const editUploadSetting = async (
  data: IUploadSettingsRequest
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.patch(`remains/edit-upload-setting`, data, { headers })
}

const deleteUploadSetting = async (
  id: string
): Promise<AxiosResponse<IRemainsFields> | undefined> => {
  const headers = getHeaders()
  if (!headers.Authorization) {
    console.error(new Error('Set Headers first!'))
    return
  }
  return _axios.delete(`remains/delete-upload-setting`, {
    headers,
    params: { id }
  })
}

//search
const getBrandsByPartNumber = async (
  partNumber: string
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  return _axios.get(`search/filters`, { headers, params: { partNumber } })
}

const getOnePart = async (
  data: IGetAnalogsParams
): Promise<AxiosResponse | undefined> => {
  return _axios.get(`search/get-one`, {
    params: { partNumber: data.partNumber, brand: data.brand }
  })
}

const getAnalogsByPartNumberBrand = async (
  data: IGetAnalogsParams
): Promise<AxiosResponse | undefined> => {
  const headers = getHeaders()
  return _axios.get(`search/analogs`, {
    headers,
    params: { partNumber: data.partNumber, brand: data.brand }
  })
}

/* 
//TODO: Do not remove! TEHNOMIR API
const getBrandsByPartNumber = async (
  partNumber: string
): Promise<AxiosResponse | undefined> => {
  return _axios.get(`search/brands-by-partNumber`, { params: { partNumber } })
}

const getAnalogsByPartNumberBrand = async (
  partNumber: string,
  brandId: string
): Promise<AxiosResponse | undefined> => {
  return _axios.get(`search/analogs-by-partNumber-brand`, {
    params: { partNumber, brandId }
  })
} */

const api = {
  ...orders,
  ...usersApi,
  ...providers,
  ...currenciesApi,
  ...brandsApi,
  ...logsApi,
  ...partsApi,
  ...catalogueApi,
  getUploadSettings,
  addUploadSetting,
  editUploadSetting,
  getFileFields,
  getStorages,
  updateFileFields,
  updateStorages,
  deleteStorage,
  deleteUploadSetting,
  uploadRemains,
  getBrandsByPartNumber,
  getOnePart,
  getAnalogsByPartNumberBrand,
  deleteRemainsByProviderId
}

export { api }
