import React, { FC } from 'react'
import { MenuItem, Select, Typography } from '@mui/material'

interface IOption {
  title: string
  value: number | string
}

interface IFieldSelect {
  value: string | number
  label: string
  name: string
  handleChange: (value: any) => void
  handleBlur: (value: any) => void
  errors: any
  touched: any
  options: IOption[]
  [key: string]: any
}

export const FieldSelect: FC<IFieldSelect> = ({
  value,
  label,
  name,
  handleChange,
  handleBlur,
  errors,
  touched,
  options,
  ...rest
}) => {
  return (
    <>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}>
        {options.map((option) => (
          <MenuItem
            key={'selectOption' + option.title + option.value}
            value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      {Boolean(errors[name] && touched[name]) && (
        <Typography color="red" mt={1} fontSize={14}>
          {errors[name]}
        </Typography>
      )}
    </>
  )
}
