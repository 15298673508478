import React, { useState } from 'react'
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useQuery } from 'react-query'
import { api } from '../../../utils/api'
import { Loader } from '../../common'
import { ILogs } from '../../../types'
import styled from 'styled-components'
import moment from 'moment'

export const LogsList = () => {
  const [page, setPage] = useState(1)
  const { data: logsList, isLoading: isLogsLoading } = useQuery(
    ['logs', page],
    () => api.getAllLogs({ page, offset: 50 })
  )
  const rows: ILogs[] = logsList?.data?.data || []
  const count =
    logsList?.data?.count !== undefined
      ? Math.ceil(logsList?.data?.count / 50)
      : 0

  return (
    <>
      {isLogsLoading && <Loader />}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell>Method</StyledTableCell>
              <StyledTableCell align="left">URL</StyledTableCell>
              <StyledTableCell align="left">User</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(rows) &&
              rows.map((row: ILogs) => (
                <TableRow
                  key={'logsList' + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell align="right">{row.id}</StyledTableCell>
                  <StyledTableCell align="left">{row.method}</StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ wordBreak: 'break-word' }}>
                    {row.url}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.user?.id
                      ? `${row?.user?.alias || row?.user?.name} (id: ${
                          row?.user?.id
                        })`
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(new Date(row.createdAt)).format('DD/MM/YYYY HH:mm')}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!Boolean(rows.length) && (
        <Box textAlign="center" my={4}>
          Nothing was found
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="center" my={6}>
        <Pagination
          count={count}
          page={page}
          color="primary"
          onChange={(e, val) => setPage(val)}
        />
      </Box>
    </>
  )
}

export const StyledTableCell = styled(TableCell)`
  padding: 18px 16px !important;
`
