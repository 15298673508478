import React, { useEffect, useState } from 'react'
import { AddProviderModal, ProvidersList } from '../../components'
import { Box, Typography } from '@mui/material'
import { IProvider } from '../../types'

export const ProvidersPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [defaultValues, setDefaultValues] = useState<IProvider | undefined>(
    undefined
  )

  useEffect(() => {
    if (Boolean(defaultValues) && !isOpen) setIsOpen(true)
  }, [defaultValues, isOpen])

  return (
    <>
      <Box mb={2}>
        <Typography fontSize="25px" fontWeight={500}>
          Providers{' '}
          <AddProviderModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            defaultValues={defaultValues}
            setDefaultValues={setDefaultValues}
          />
        </Typography>
      </Box>
      <ProvidersList setDefaultValues={setDefaultValues} />
    </>
  )
}
