import React from 'react'
import { useFormik } from 'formik'
import { Box, Button, Typography } from '@mui/material'
import { useMutation } from 'react-query'
import { api } from '../../utils/api'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FieldPassword } from '../common'
import { newPasswordValidation } from './validations'

export const NewPasswordForm = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  const navigate = useNavigate()
  const newPasswordMutation = useMutation(api.updateForgotPassword, {
    retry: false,
    onSuccess: () => {
      navigate('/login')
      toast.success(
        'Пароль оновлено. Тепер ви можете використати його для входу в свій обліковий запис!',
        {
          autoClose: 10000
        }
      )
    }
  })

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        password: '',
        passwordRepeat: '',
        token
      },
      validationSchema: newPasswordValidation,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        if (token) newPasswordMutation.mutate(values)
      }
    })

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Typography
        variant="h4"
        textAlign="center"
        fontWeight={500}
        paddingBottom="20px"
        lineHeight={0.95}>
        Зміна паролю
      </Typography>

      <FieldPassword
        name="password"
        value={values['password']}
        label="Новий пароль"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <FieldPassword
        name="passwordRepeat"
        value={values['passwordRepeat']}
        label="Повторіть пароль"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <Box mt={6}>
        <StyledSubmit type="submit" variant="outlined" fullWidth>
          Оновити пароль
        </StyledSubmit>
      </Box>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  padding: 50px 30px;
  border-radius: 30px;
  flex-direction: column;
  margin: 40px 20px;
  border: 1px solid #c5c5c5;
  background-color: #fff;
`

const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
