import React, { FC, useState } from 'react'
import {
  Box,
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
  Typography
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
interface IProps extends StandardTextFieldProps {
  value: string | number
  label: string
  name: string
  handleChange: (value: any) => void
  handleBlur: (value: any) => void
  errors: any
  touched: any
  [key: string]: any
}
export const FieldPassword: FC<IProps> = ({
  value,
  label,
  name,
  handleChange,
  errors,
  touched,
  handleBlur,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Box my={2}>
      <TextField
        value={value}
        label={label}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {/* eslint-disable-next-line react/jsx-no-undef */}
      {Boolean(errors[name] && touched[name]) && (
        <Typography color="red" mt={1} fontSize={14}>
          {errors[name]}
        </Typography>
      )}
    </Box>
  )
}
