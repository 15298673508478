import moment from 'moment'

export const getDeliveryDate = (days: number, today: Date): Date => {
  let newDate = new Date()
  newDate.setDate(today.getDate() + days)
  return newDate
}

export const momentFormattedDeliveryDate = (delivery: number, date: Date) => {
  const deliveryDate = getDeliveryDate(Number(delivery), date)

  if (deliveryDate.getDay() === 0) {
    //if will be delivered on Sunday
    return moment(getDeliveryDate(Number(delivery) + 1, date))
      .locale('uk')
      .format('DD MMMM (dddd)')
  }

  return moment(getDeliveryDate(Number(delivery), date))
    .locale('uk')
    .format('DD MMMM (dddd)')
}

export const getDelivery = (
  delivery: string,
  orderBy: string,
  currentDate: Date
) => {
  if (!isNaN(Number(delivery))) {
    const now = moment()
    if (currentDate.getDay() == 6) {
      //if Saturday
      return momentFormattedDeliveryDate(Number(delivery) + 2, currentDate)
    } else if (currentDate.getDay() == 0) {
      //if Sunday
      return momentFormattedDeliveryDate(Number(delivery) + 1, currentDate)
    } else {
      if (!moment(orderBy, 'HH:mm').isAfter(now)) {
        //if after limit order time
        return momentFormattedDeliveryDate(Number(delivery) + 1, currentDate)
      } else {
        //default
        return momentFormattedDeliveryDate(Number(delivery), currentDate)
      }
    }
  } else {
    return delivery
  }
}
