import React from 'react'
import { useFormik } from 'formik'
import { FieldText } from '../common'
import { Box, Button, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { resetValidation } from './validations'

export const ResetPasswordForm = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const resetPasswordMutation = useMutation(api.resetPassword, {
    retry: false,
    onSuccess: () => {
      navigate('/')
      toast.success('Лист з інструкцією відправлено на пошту!', {
        autoClose: 20000
      })
    }
  })

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: ''
      },
      validationSchema: resetValidation,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        resetPasswordMutation.mutate({
          ...values,
          email: values.email.toLowerCase()
        })
      }
    })

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Typography
        variant="h4"
        textAlign="center"
        fontWeight={500}
        paddingBottom="20px"
        lineHeight={0.95}>
        Зміна паролю
      </Typography>

      <FieldText
        name="email"
        value={values['email']}
        label="Введіть Email"
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />

      <Box mt={6}>
        <StyledSubmit type="submit" variant="outlined" fullWidth>
          Відновити пароль
        </StyledSubmit>
      </Box>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  padding: 50px 30px;
  border-radius: 30px;
  flex-direction: column;
  margin: 40px 20px;
  border: 1px solid #c5c5c5;
  background-color: #fff;
`

const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
