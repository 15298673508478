import { Roles } from './roles'

export enum PriceGroup {
  RETAIL = 'RETAIL',
  WHOLESALE1 = 'WHOLESALE1',
  WHOLESALE2 = 'WHOLESALE2',
  WHOLESALE3 = 'WHOLESALE3'
}

export interface IUserPriceGroup {
  id: string
  name: PriceGroup
  multiplier: number
}

export interface IUser {
  id: string
  address?: string
  city: string
  createdAt: Date
  email: string
  irresponsible: boolean
  name: string
  phone: string
  roles: Roles
  alias?: string
  price_group?: IUserPriceGroup
  info_price_available: boolean
  info_price_percents?: number
}

export interface IUserUpdateRequest {
  id: string
  address?: string
  city: string
  email: string
  name: string
  phone: string
}

export interface IChangePasswordRequest {
  oldPassword: string
  password: string
}

export interface IForgotUpdatePasswordRequest {
  password: string
  passwordRepeat: string
  token: string
}

export interface IResetPasswordRequest {
  email: string
}
