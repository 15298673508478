import React, { FC } from 'react'
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Pagination,
  Tooltip,
  IconButton
} from '@mui/material'
import { ICreatePartBody, IPart, ISearchPartsResponse } from '../../../types'
import styled from 'styled-components'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import EditIcon from '@mui/icons-material/Edit'
import { colors } from '../../../theme'
import { Link } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'

interface IPartsList {
  searchData: ISearchPartsResponse
  page: number
  setPage: (p: number) => void
  setDefaultValues: React.Dispatch<
    React.SetStateAction<ICreatePartBody | undefined>
  >
}

export const PartsList: FC<IPartsList> = ({
  searchData,
  page,
  setPage,
  setDefaultValues
}) => {
  const rows: IPart[] = searchData?.data || []
  const count = Math.ceil(searchData?.count / 50) || 0
  const queryClient = useQueryClient()
  const { mutate: deletePart } = useMutation(api.deletePart, {
    onSuccess: () => {
      queryClient.invalidateQueries(['parts'])
    },
    retry: false
  })

  const onPageChange = (e: React.ChangeEvent<unknown>, val: number) => {
    setPage(val)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell>Код</StyledTableCell>
              <StyledTableCell align="left">Бренд</StyledTableCell>
              <StyledTableCell align="left">Назва</StyledTableCell>
              <StyledTableCell align="left">Налаштування</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(rows) &&
              rows.map((row: IPart) => (
                <TableRow
                  key={'partsList' + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.partNumberDirty}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.brand.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="left">
                    <Box
                      display="flex"
                      alignItems="end"
                      justifyContent="flex-start">
                      <StyledLink to={`/admin/parts/${row.id}/`}>
                        <OpenInNewIcon
                          fill={colors.dark2}
                          style={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px'
                          }}
                        />
                      </StyledLink>

                      <Box ml={2}>
                        <Tooltip title="Редагувати">
                          <IconButton
                            onClick={() => {
                              if (row.id)
                                setDefaultValues({
                                  partNumber: row.partNumberDirty,
                                  name: row.name,
                                  brandId: row.brand.id,
                                  id: row.id
                                })
                            }}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Box ml={2}>
                        <Tooltip title="Подвійний клік для видалення">
                          <IconButton
                            onDoubleClick={() => {
                              if (row.id) deletePart(row.id)
                            }}>
                            <RemoveCircleRoundedIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!Boolean(rows.length) && (
        <Box textAlign="center" my={4}>
          Nothing was found
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="center" my={6}>
        <Pagination
          count={count}
          page={page}
          color="primary"
          onChange={onPageChange}
        />
      </Box>
    </>
  )
}

export const StyledTableCell = styled(TableCell)`
  padding: 18px 16px !important;
`

const StyledLink = styled(Link)`
  color: ${colors.dark2};
`
