import React, { FC, useState } from 'react'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'react-toastify/dist/ReactToastify.css'
import { AppRoutes } from './routes'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { toast, ToastContainer } from 'react-toastify'
import { AxiosError } from 'axios'
import { deleteCookie } from './utils/cookies'
import { Box, ThemeProvider } from '@mui/material'
import { theme } from './theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const App: FC = () => {
  const mutationCache = new MutationCache({
    onError: (error, _variables, _context) => {
      const errorResp = error as AxiosError
      if (errorResp?.response?.status === 401) {
        deleteCookie('auth')
        window.location.href = '/login'
      }
      toast.error(
        // @ts-ignore
        errorResp?.response?.data?.message ||
          errorResp?.message ||
          'Щось пішло не так.'
      )
    }
  })

  const queryCache = new QueryCache({
    onError: (error) => {
      const errorResp = error as AxiosError
      if (errorResp?.response?.status === 401) {
        deleteCookie('auth')
        window.location.href = '/login'
      }
      toast.error(
        // @ts-ignore
        errorResp?.response?.data?.message ||
          errorResp?.message ||
          'Щось пішло не так.'
      )
    }
  })

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache,
        queryCache,
        defaultOptions: {
          queries: {
            retry: 1,
            retryDelay: 10000,
            refetchOnWindowFocus: false
          },
          mutations: {
            retry: 1,
            retryDelay: 10000
          }
        }
      })
  )
  return (
    <Box
      display="flex"
      flexDirection="column"
      className="App"
      minHeight="100vh">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            <ToastContainer position="bottom-right" autoClose={2000} />
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Box>
  )
}

export default App
