import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { api } from '../utils/api'
import { Navigate } from 'react-router'
import { Loader } from '../components'
import { Roles } from '../types'
import { toast } from 'react-toastify'
import { useCookies } from 'react-cookie'
import { deleteCookie } from '../utils/cookies'

interface IShouldBe {
  children: React.ReactNode
}

export const ShouldBeLoggedIn: FC<IShouldBe> = ({ children }) => {
  const [cookies] = useCookies(['auth'])

  if (!cookies?.auth) return <Navigate to="/login" />
  return <>{children}</>
}

export const ShouldNotBeLoggedIn: FC<IShouldBe> = ({ children }) => {
  const [cookies] = useCookies(['auth'])

  if (cookies?.auth) return <Navigate to="/" />
  return <>{children}</>
}

export const ShouldBeAdmin: FC<IShouldBe> = ({ children }) => {
  const [cookies] = useCookies(['auth'])
  const { isLoading, data, isError } = useQuery('me', api.getMe, {
    enabled: Boolean(cookies?.auth),
    onError: () => deleteCookie('auth', { path: '/' })
  })

  if (!cookies?.auth) return <Navigate to="/login" />

  if (isLoading) return <Loader />

  if ((data?.data?.roles && data?.data?.roles !== Roles.ADMIN) || isError) {
    toast.error('You are not allowed to see this page')
    return <Navigate to="/" />
  }

  return <>{children}</>
}
