import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const getCookie = (name: string) => {
  return cookies.get(name)
}

export const setCookie = (name: string, value: any, options = {}) => {
  cookies.set(name, value, options)
}

export const deleteCookie = (name: string, options?: any) => {
  cookies.remove(name, options)
}
