import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { useCookies } from 'react-cookie'
import { useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { Roles } from '../../types/roles'
import { colors } from '../../theme'
import { CartModal } from '../CartModal'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { deleteCookie } from '../../utils/cookies'
import { setFavicon } from '../../utils/updateFavicon'
import { isMobile } from 'react-device-detect'
import { ICart } from '../../types/cart'
import useLocalStorage from 'use-local-storage'
import './header.css'

export const Header: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth', 'cart'])
  const [cartLocal, setCartLocal] = useLocalStorage<ICart>('cart', {
    comment: '',
    items: []
  })

  const [isCartOpen, setIsCartOpen] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()
  const { data } = useQuery('me', api.getMe, {
    enabled: Boolean(cookies?.auth),
    onError: () => deleteCookie('auth', { path: '/' })
  })

  const { data: countUnprocessedOrders } = useQuery(
    'unprocessedOrders',
    api.countUnprocessedOrders,
    {
      onSuccess: (resp) => {
        if (resp?.data) {
          setFavicon('/favicon_new_order.ico')
        } else {
          setFavicon('/favicon.ico')
        }
      },
      onError: () => {
        setFavicon('/favicon.ico')
      },
      enabled: Boolean(data?.data?.roles === Roles.ADMIN),
      refetchInterval: 60000,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true
    }
  )

  useEffect(() => {
    if (!isMobile) {
      if (countUnprocessedOrders?.data) {
        if (countUnprocessedOrders.data > 0) {
          new Notification('Авто Край. Нове замовлення!')
        }
      }
    }
  }, [countUnprocessedOrders?.data])

  useEffect(() => {
    if (!isMobile) {
      if (!('Notification' in window)) {
        console.log('Browser does not support desktop notification')
      } else {
        if (Boolean(data?.data?.roles === Roles.ADMIN))
          Notification.requestPermission()
      }
    }
  }, [data?.data?.roles])

  const queryClient = useQueryClient()

  const handleLogout = () => {
    removeCookie('auth', { path: '/' })
    removeCookie('cart', { path: '/' })
    setCartLocal({ comment: '', items: [] })
    queryClient.resetQueries(['me'])
    window.location.reload()
  }

  const cart = cartLocal

  //TODO: remove after 01.10.2023
  useEffect(() => {
    if (cookies?.cart) {
      console.log('cookies?.cart', cookies?.cart)
      setCartLocal(cookies.cart)
      removeCookie('cart', { path: '/' })
    }
  }, [])

  const newAdminOrdersMessage = countUnprocessedOrders?.data ? (
    <Box mr={2} display="inline">
      <Link to="/admin/orders" className="unprocessed-orders">
        Необроблені замовлення: {countUnprocessedOrders?.data}
      </Link>
    </Box>
  ) : (
    <></>
  )

  return (
    <StyledHeaderWrapper>
      <Box>
        <StyledLogoLink to="/">
          <Typography
            textAlign="center"
            fontWeight={600}
            lineHeight={0.95}
            fontSize={20}
            color={colors.dark2}>
            АВТО <br /> КРАЙ
          </Typography>
        </StyledLogoLink>
      </Box>
      <Box>
        {newAdminOrdersMessage}
        {Boolean(data?.data?.roles === Roles.ADMIN) && (
          <Box mr={2} display="inline">
            <Link to="/admin" style={{ color: 'blue' }}>
              Панель
            </Link>
          </Box>
        )}

        {!Boolean(cookies?.auth) ? (
          <Button variant="outlined" onClick={() => navigate('/login')}>
            Увійти
          </Button>
        ) : (
          <>
            <Button variant="outlined" onClick={() => navigate('/search-vin')}>
              VIN каталог
            </Button>

            <Box display="inline">
              <Tooltip title="Корзина">
                <span>
                  <IconButton
                    onClick={() => setIsCartOpen(true)}
                    size="medium"
                    disabled={!Boolean(cart?.items?.length)}
                    sx={{ ml: 2 }}>
                    <StyledBadge
                      badgeContent={cart?.items?.length}
                      color="secondary">
                      <ShoppingCartIcon />
                    </StyledBadge>
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <Tooltip title="Мій профіль">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <Avatar sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <Box p="6px 16px">
                <Typography fontSize={13}>
                  Код Клієнта: {data?.data?.id}
                </Typography>
              </Box>
              <Divider />
              <MenuItem onClick={() => navigate('/orders')}>
                <ListItemIcon>
                  <ListAltIcon fontSize="small" />
                </ListItemIcon>
                Мої замовлення
              </MenuItem>
              <MenuItem onClick={() => navigate('/settings')}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Налаштування
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Вийти
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <CartModal isOpen={isCartOpen} setIsOpen={setIsCartOpen} />
    </StyledHeaderWrapper>
  )
}

const StyledHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  padding: 20px 30px;
  margin: 0 auto;
  z-index: 1;
`

const StyledLogoLink = styled(Link)`
  text-decoration: none;
  z-index: 1;
`
const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    right: -3px;
    top: 13px;
    border: 2px solid #fff;
    padding: 0 4px;
  }
`
