import React, { useEffect, useState } from 'react'
import { Loader, UploadSettings } from '../../components'
import { useParams } from 'react-router'
import { Box, Button, Typography } from '@mui/material'
import { UploadSettingModal } from '../../components/admin/Providers/UploadSettingModal'
import { IUploadSettings, IUploadSettingsRequest } from '../../types'
import { useQuery } from 'react-query'
import { api } from '../../utils/api'
import { utils, write } from 'xlsx'
import { saveAs } from 'file-saver'

export const ProviderUploadSettingsPage = () => {
  const { id } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [defaultValues, setDefaultValues] = useState<
    IUploadSettingsRequest | undefined
  >(undefined)

  const { data: settingsData, isLoading: settingsLoading } = useQuery(
    ['uploadSettings', id],
    () => api.getUploadSettings(id || ''),
    {
      enabled: Boolean(id)
    }
  )
  const { data: providerData, isLoading: providerLoading } = useQuery(
    ['provider', id],
    () => api.getProviderById(id || ''),
    { enabled: Boolean(id) }
  )

  const settingRows: IUploadSettings[] = settingsData?.data || []
  const providerName = providerData?.data?.name || 'ProviderName'

  useEffect(() => {
    if (Boolean(defaultValues) && !isOpen) setIsOpen(true)
  }, [defaultValues, isOpen])

  useEffect(() => {
    if (providerData?.data?.name) {
      document.title = `⚙️ ${providerData?.data?.name}`
    }

    return () => {
      document.title = 'Авто Край'
    }
  }, [providerData?.data?.name])

  const onDownloadBtn = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const ws = utils.json_to_sheet(
      settingRows.map((item) => ({
        brand: item?.brand?.name || '',
        brand_file: item.brand_file,
        prefix_add: item.prefix_add,
        prefix_remove: item.prefix_remove,
        suffix_add: item.suffix_add,
        suffix_remove: item.suffix_remove
      }))
    )
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    saveAs(data, providerName + '_settings.xlsx')
  }

  return (
    <>
      {Boolean(settingsLoading || providerLoading) && <Loader />}

      <Box mb={2}>
        <Typography fontSize="25px" fontWeight={500}>
          Upload Settings: {providerName}
        </Typography>
        <Box display="flex" mt={3} mb={5} gap={3}>
          <Button variant="contained" onClick={() => setIsOpen(true)}>
            Add Setting
          </Button>
          <Button variant="contained" onClick={onDownloadBtn}>
            Download
          </Button>
        </Box>
      </Box>
      <UploadSettings providerId={id || ''} setEdit={setDefaultValues} />
      <UploadSettingModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
      />
    </>
  )
}
