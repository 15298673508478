import React, { FC } from 'react'
import { ICatalogueGroupUnitDetails } from '../../types'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '../../theme'
import { DescriptionTable } from './DescriptionTable'
import InfoIcon from '@mui/icons-material/Info'

interface ICataloguePartsList {
  list: ICatalogueGroupUnitDetails[]
  codeHovered?: string | null
  setCodeHovered?: (p: string | null) => any
  codeSelected?: string | null
  setCodeSelected?: (p: string | null) => any
}

export const CataloguePartsList: FC<ICataloguePartsList> = ({
  list,
  codeHovered,
  setCodeHovered,
  codeSelected,
  setCodeSelected
}) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '300px' }} aria-label="parts list table">
          <TableBody>
            {list.map((item) => {
              const isHighlited =
                codeHovered === item?.codeonimage ||
                codeSelected === item?.codeonimage
              return (
                <TableRow
                  key={'part' + item?.oem + item?.name + item?.codeonimage}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: isHighlited ? '#d8d8d8' : 'transparent'
                  }}
                  onMouseEnter={() =>
                    setCodeHovered && setCodeHovered(item?.codeonimage)
                  }
                  onMouseLeave={() => setCodeHovered && setCodeHovered(null)}
                  onClick={() =>
                    setCodeSelected && setCodeSelected(item?.codeonimage)
                  }>
                  <TableCell component="th" scope="row" sx={{ padding: 0 }}>
                    <Typography fontWeight={500}>
                      {item?.codeonimage}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: '0 0 0 5px' }}>
                    {Boolean(item?.oem) ? (
                      <StyledLink
                        to={`/?partNumber=${item?.oem}`}
                        target="_blank">
                        <Typography fontWeight={500}>{item?.oem}</Typography>
                      </StyledLink>
                    ) : (
                      <Typography fontWeight={500}>{item?.oem}</Typography>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '0 0 0 5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                    {Boolean(item?.oem) ? (
                      <StyledLink
                        to={`/?partNumber=${item?.oem}`}
                        target="_blank">
                        <Typography fontWeight={500}>{item?.name}</Typography>
                      </StyledLink>
                    ) : (
                      <Typography fontWeight={500}>{item?.name}</Typography>
                    )}
                    <Box display="flex" alignItems="center" mx={1}>
                      <StyledTooltip
                        title={
                          <DescriptionTable
                            descriptionItems={item?.attributes || []}
                          />
                        }>
                        <InfoIcon />
                      </StyledTooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const StyledLink = styled(Link)`
  color: ${colors.dark2};
`

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`
