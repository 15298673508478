import React, { FC, ReactNode } from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router'
import { Header, Navigation } from '../components'
import styled from 'styled-components'
import { colors } from '../theme'
import { isMobile } from 'react-device-detect'

interface IProps {
  children?: ReactNode
}

export const AdminLayout: FC<IProps> = ({ children }) => {
  return (
    <>
      <Box>
        <Header />
      </Box>
      {Boolean(children) && children}
      <Box flexGrow={1} display="flex" alignItems="stretch">
        <Navigation />
        <StyledContentWrapper py={4} px={isMobile ? 1 : 4}>
          <Outlet />
        </StyledContentWrapper>
      </Box>
    </>
  )
}

const StyledContentWrapper = styled(Box)`
  border-top: 1px solid ${colors.dark2};
  flex-grow: 1;
  overflow: hidden;
`
