import React, { FC } from 'react'
import { ICatalogueAttributesItem } from '../../types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'

interface IDescriptionTable {
  descriptionItems: ICatalogueAttributesItem[]
}

export const DescriptionTable: FC<IDescriptionTable> = ({
  descriptionItems
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 280 }} aria-label="simple table">
        <TableBody>
          {descriptionItems.map((row) => (
            <TableRow
              key={row.key + row.name + row.value}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{row.key}</TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
