import React, { FC, useCallback, useState } from 'react'
import { ICatalogueGroupItem } from '../../../types'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { cloneDeep } from 'lodash'

interface ICatalogueGroupsNav {
  list: ICatalogueGroupItem[]
  onGroupSelect: (id: string, ssd?: string) => any
}

export const CatalogueGroupsNav: FC<ICatalogueGroupsNav> = ({
  list,
  onGroupSelect
}) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([])

  const expandItem = useCallback((id: string) => {
    setExpandedItems((prev) => {
      const clonedPrev = cloneDeep(prev)
      if (clonedPrev.includes(id)) {
        const index = clonedPrev.indexOf(id)

        if (index > -1) {
          clonedPrev.splice(index, 1)
        }

        return clonedPrev
      } else {
        return [...clonedPrev, id]
      }
    })
  }, [])

  const getListItem = useCallback(
    (item: ICatalogueGroupItem, paddingLeft: number = 0) => {
      return (
        <Box key={'group-nav-' + item?.id}>
          <ListItemButton
            sx={{ p: 0, pl: paddingLeft + (!item.hasChilds ? 3 : 0) }}
            onClick={() =>
              item.hasChilds
                ? expandItem(item.id)
                : onGroupSelect(item.id, item?.ssd)
            }>
            {item.hasChilds &&
              (expandedItems?.includes(item?.id) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
            <ListItemText primary={item?.name} />
          </ListItemButton>
          {item.hasChilds && (
            <Collapse
              in={expandedItems?.includes(item?.id)}
              timeout="auto"
              unmountOnExit>
              <List component="div" disablePadding>
                {item.childs.map((child) =>
                  getListItem(child, paddingLeft + 3)
                )}
              </List>
            </Collapse>
          )}
        </Box>
      )
    },
    [expandedItems, expandItem, onGroupSelect]
  )
  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Групи запчастин
          </ListSubheader>
        }>
        {list?.map((item) => getListItem(item))}
      </List>
    </>
  )
}
