import React from 'react'
import { Typography } from '@mui/material'
import { BrandsList } from '../../components'

export const BrandsPage = () => {
  return (
    <>
      <Typography fontSize="25px" fontWeight={500}>
        Brands
      </Typography>

      <BrandsList />
    </>
  )
}
