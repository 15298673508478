import React, { useEffect } from 'react'
import { Loader, UploadRemains } from '../../components'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { api } from '../../utils/api'

export const ProviderUploadRemainsPage = () => {
  const { id } = useParams()
  const { data: providerData, isLoading: providerLoading } = useQuery(
    ['provider', id],
    () => api.getProviderById(id || ''),
    { enabled: Boolean(id) }
  )

  useEffect(() => {
    if (providerData?.data?.name) {
      document.title = providerData?.data?.name
    }

    return () => {
      document.title = 'Авто Край'
    }
  }, [providerData?.data?.name])

  return (
    <>
      {Boolean(providerLoading) && <Loader />}

      <Box mb={2}>
        <Typography fontSize="25px" fontWeight={500}>
          Upload Remains: {providerData?.data?.name}
        </Typography>
      </Box>
      <UploadRemains />
    </>
  )
}
