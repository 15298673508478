import React from 'react'
import { useFormik } from 'formik'
import { FieldText } from '../common'
import { Box, Button, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { validationSchema } from './validation'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import { FieldPassword } from '../common'

export const LoginForm = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['auth'])
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const loginMutation = useMutation(api.login, {
    retry: false,
    onSuccess: ({ data }) => {
      if (data?.access_token) {
        setCookie('auth', data.access_token, { path: '/', maxAge: 2592000 })
        navigate('/')
        toast.success('Успішно!')
        queryClient.clear()
      }
    }
  })

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: ''
      },
      validationSchema,
      validateOnBlur: true,
      onSubmit: (values) => {
        loginMutation.mutate({ ...values, email: values.email.toLowerCase() })
      }
    })

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Typography
        variant="h3"
        textAlign="center"
        fontWeight={500}
        paddingBottom="20px"
        lineHeight={0.95}>
        АВТО <br /> КРАЙ
      </Typography>

      <Typography>Увійти в свій обліковий запис.</Typography>
      <FieldText
        name="email"
        value={values['email']}
        label="Email"
        handleChange={handleChange}
        errors={errors}
        required={true}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      <FieldPassword
        name="password"
        value={values['password']}
        label="Пароль"
        handleChange={handleChange}
        errors={errors}
        required={true}
        touched={touched}
        handleBlur={handleBlur}
        fullWidth
      />
      <Typography>
        Забули пароль? <Link to="/forgot-password">Змінити.</Link>
      </Typography>
      <br />
      <Typography>
        Не маєте облікового запису? <Link to="/registration">Реєстрація.</Link>
      </Typography>
      <Box mt={6}>
        <StyledSubmit type="submit" variant="outlined" fullWidth>
          Увійти
        </StyledSubmit>
      </Box>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  padding: 50px 30px;
  border-radius: 30px;
  flex-direction: column;
  margin: 40px 20px;
  border: 1px solid #c5c5c5;
  background-color: #fff;
`

const StyledSubmit = styled(Button)`
  padding: 13px !important;
`
